import { TimeOutActionType } from "../Constants/TimeOutActionType";

const initialState = {
	timeOutData: {},
};

export const TimeOutReducers = (state = initialState, action) => {
	switch (action.type) {
	case TimeOutActionType.SET_TIMEOUT:
		return {
			...state,
			timeOutData: action.payload,
		};
	default:
		return state;
	}
};
