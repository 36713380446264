// ButtonLoader
import {Spinner} from "react-bootstrap";
import PropTypes from "prop-types";

const ButtonLoader = (props) => {

    const {loading, className} = props;
    const render = () => {
        return (
            <Spinner animation="border" role="status" className={className}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    return loading && render();
}

ButtonLoader.propTypes = {
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired
}

ButtonLoader.defaultProps = {
    className: 'text-white'
}

export default ButtonLoader;
