import {SelectionTeamActionType} from "../Constants/SelectionTeamActionType";
import {PaymentActionType} from "../Constants/PaymentActionType";

const initialState = {
	preparedData: [],
};

export const PaymentDataReducer = (state = initialState, action) => {
	switch (action.type) {
	case PaymentActionType.SET_PAYMENT_PREPARED_DATA:
		return {
			...state,
			preparedData: action.payload,
		};
	default:
		return state;
	}
};
