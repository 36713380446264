import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {post} from "../Axios/post";
import {LOGIN, STAGING_BASE_URL} from "../Axios/EndPoints";
import * as yup from 'yup';
import {Formik} from "formik";
import SmallLoader from "./SmallLoader";
import {useDispatch} from "react-redux";
import {setIsLogin, setToken, setUser} from "../redux/Actions/LoginAction";
import ForgotPasswordModal from "./ForgotPasswordModal";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    setDisplayName,
    setEmailRedux,
    setKyc,
    setUserNameRedux,
    setUserRedux
} from "../redux/Actions/AuthenticationAction";
import ButtonLoader from "./ButtonLoader";

const LoginModal = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const {showModal, setShowModal} = props;

    const [loading, setLoading] = useState(false);

    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
    });

    const handleLogin = (values) => {
        setLoading(true);
        setErrorMessage("");
        let isStaging = parseInt(params.get('staging'));
        post(LOGIN, values, false, '', null, isStaging ? STAGING_BASE_URL : '')
            .then((response) => {
                if (response?.data?.success) {
                    values.email = '';
                    values.password = '';
                    dispatch(setIsLogin(true));
                    dispatch(setUser(response?.data?.data));
                    dispatch(setToken(response?.data?.data?.token));

                    dispatch(setDisplayName(response.data?.data?.user?.display_name));
                    dispatch(setUserRedux(response.data?.data));
                    dispatch(setUserNameRedux(response?.data?.data?.user?.username));
                    dispatch(setEmailRedux(response?.data?.data?.user?.email));
                    dispatch(setKyc(response?.data?.data?.user?.kyc));
                    setShowModal(false);
                }else {
                    setErrorMessage(response?.data?.message || "Login failed. Please check your credentials.");
                }
            })
            .catch((error) => {
                console.error('contact submit error', error.response);
                setErrorMessage(error?.response?.data?.message || "Login failed. Please check your credentials.");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {

    }, [showModal]);

    const renderForm = () => {
        return (
            <Formik
                validationSchema={schema}
                onSubmit={handleLogin}
                initialValues={{
                    email: '',
                    password: '',
                }}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({handleSubmit, handleChange, values, touched, errors}) => {
                    const customHandleChange = (e) => {
                        handleChange(e);
                        setErrorMessage(""); // Clear the error message
                    };
                    return(
                        <Form className={'login-form p-2'} noValidate onSubmit={handleSubmit}>
                            {errorMessage && (
                                <Row>
                                    <Col>
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            id={'email'}
                                            type="email" placeholder="Email *"
                                            value={values.email}
                                            onChange={customHandleChange}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={!!errors.email}
                                        />
                                        {/*<Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>*/}
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.email + ''}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            id={'password'}
                                            type="password" placeholder="Password *"
                                            value={values.password}
                                            onChange={customHandleChange}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={!!errors.password}
                                        />
                                        {/*<Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>*/}
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.password + ''}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className={'d-grid'}>
                                    <Button className={'btn-skins-red'} type={'submit'} size={'lg'}>
                                        <ButtonLoader loading={loading}/>
                                        {
                                            !loading &&
                                            (
                                                <>
                                                    Log in
                                                </>
                                            )
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        );
    }

    const render = () => {
        return (
            <section>
                <Modal
                    show={showModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => {
                        setShowModal(false)
                    }}
                >
                    <Modal.Body className={'text-center'} style={{backgroundColor: '#f3f1f7'}}>
                        {renderForm()}
                        <Row className={'p-2'}>
                            <Col md={12} className={'d-grid'}>
                                <Button className={'btn-skins-blue'} type={'submit'} size={'lg'}
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setShowModal(false);
                                            navigate("/register")
                                        }}
                                >
                                    Signup
                                    {/*<SmallLoader loading={loading}/>*/}
                                    {/*{*/}
                                    {/*    !loading &&*/}
                                    {/*    (*/}
                                    {/*        <>*/}
                                    {/*            Signup*/}
                                    {/*        </>*/}
                                    {/*    )*/}
                                    {/*}*/}
                                </Button>
                            </Col>
                        </Row>
                        <Row className={'p-2'}>
                            <Col className={'text-start roboto fw-bold font-18'}>
                                <label className={'form-check-label'}>
                                    <input type={'checkbox'} className={'form-check-input'}/>
                                    {" "}
                                    Remember Me
                                </label>
                            </Col>
                            <Col className={'text-end roboto fw-bold font-18'}>
                                <span role={'button'}
                                      onClick={() => {
                                          setShowForgotPasswordModal(true);
                                          setShowModal(false);
                                      }}>Forgot Password?</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <ForgotPasswordModal showModal={showForgotPasswordModal} setShowModal={setShowForgotPasswordModal}/>
            </section>
        );
    }

    return render();
}

LoginModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
}

export default LoginModal;
