import {UserCardDetailsActionType} from "../Constants/UserCardDetailsActionType";

const initialState = {
	userCardDetails: {},
};

export const UserCardDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
	case UserCardDetailsActionType.SET_USER_CARD_DETAILS:
		return {
			...state,
			userCardDetails: action.payload,
		};
	default:
		return state;
	}
};
