import React, {useRef, useState} from "react";
import moment from "moment";
import {Form, Row} from "react-bootstrap";

const PersonalDetailsSignUpComponent = (props) => {
    const {
        isMobile,
        title,
        setTitle,
        firstName,
        setFirstName,
        surname,
        setSurname,
        dateOfBirth,
        setDateOfBirth,
        userUnderEighteen
    } = props;
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

    const ref_input2 = useRef();
    const ref_input3 = useRef();

    const options = [
        {label: "Mr", value: "Mr"},
        {label: "Mrs", value: "Mrs"},
        {label: "Ms", value: "Ms"},
        {label: "Miss", value: "Miss"},
        {label: "Prefer Not to Say", value: "Prefer Not to Say"},
    ];

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };
    const handleDateChange = (e) => {
        // setDateOfBirth(e.target.value);
        setDateOfBirth(moment(e.target.value).format("YYYY-MM-DD"));
    };

    const handleConfirm = (dateofBirth) => {
        setDateOfBirth(moment(dateofBirth).format("YYYY-MM-DD"));
        hideDatePicker();
    };

    const renderTop = () => {
        return (
            <>
                <div className={"d-flex flex-column"}>
                    <div className={`fw-bold cabin ${isMobile ? "font-20":"font-30"}`}>
                        Personal Information
                    </div>
                    <div className={`cabin ${isMobile ? "font-16" : "font-15"}`}>
                        Please enter accurate information. Your identity must be
                        verified to allow continued use of your account.
                    </div>
                </div>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                {renderTop()}
                <div className={`fw-bold font-20 cabin mt-3`}>
                    Title
                </div>
                <div className={"d-flex flex-column align-items-center justify-content-start gap-2"}>
                    <Form.Select
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    >
                        <option disabled>Select Gender</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control
                        type="text"
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        ref={ref_input2}
                    />
                </div>

                <div className={`fw-bold font-20 cabin`}>
                    Date of Birth
                </div>
                <Form.Control
                    type="date"
                    value={dateOfBirth}
                    onChange={handleDateChange}
                />
                {
                    userUnderEighteen &&
                    <>
                        <div className={'fw-bold font-10 text-losing cabin'}>
                            You are under 18 and unable to play Sport Skins.
                        </div>
                    </>
                }
            </>
        );
    }
    const renderDesktop = () => {
        return (
            <>
                <div className={"d-flex flex-column gap-3"}>
                    {renderTop()}
                    <div className={"d-flex align-items-center justify-content-start gap-4"}>
                        <div className={"w-75 d-flex flex-column gap-1"}>
                            <div className={`fw-bold font-20 cabin`}>
                                Title
                            </div>
                            <div className={"d-flex gap-3"}>
                                <Form.Select
                                    className={"w-25"}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                    <option disabled>Select Gender</option>
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control
                                    type="text"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}

                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Surname"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                    ref={ref_input2}
                                />
                            </div>
                        </div>
                        <div className={"w-25 d-flex flex-column gap-1"}>
                            <div className={'fw-bold font-20 cabin'}>
                                Date of Birth
                            </div>
                            <Form.Control
                                type="date"
                                value={dateOfBirth}
                                onChange={handleDateChange}
                            />
                            {
                                userUnderEighteen &&
                                <>
                                    <div className={'fw-bold font-10 text-losing cabin'}>
                                        You are under 18 and unable to play Sport Skins.
                                    </div>
                                </>
                            }
                        </div>
                    </div>



                </div>


            </>
        );
    }
    const renderMain = () => {
        return (
            <>
                {isMobile ? renderMobile() : renderDesktop()}
            </>
        );
    };

    return renderMain();
};

export default PersonalDetailsSignUpComponent;