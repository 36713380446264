import { combineReducers } from "redux";
import { LoginReducer } from "./LoginReducer";
import {AuthenticationReducers} from "./AuthenticationReducers";
import {SelectionReducers} from "./SelectionReducers";
import {AppSettingsReducers} from "./AppSettingsReducers";
import {FromPaymentScreenReducer} from "./FromPaymentScreenReducers";
import {PaymentDataReducer} from "./PaymentPreparedData";
import {SelectedSkinDataReducers} from "./SelectedSkinDataReducers";
import {IsEditReducer} from "./IsEditReducer";
import {UserCardDetailsReducer} from "./UserCardDetailsReducers";
import {TimeOutReducers} from "./TimeOutReducers";
import {SpendLimitReducers} from "./SpendLimitReducers";

const reducers = combineReducers({
  Login: LoginReducer,
  Authentication: AuthenticationReducers,
  TeamSelection: SelectionReducers,
  AppSettings: AppSettingsReducers,
  FromPaymentScreen: FromPaymentScreenReducer,
  PaymentData: PaymentDataReducer,
  SelectedSkinData: SelectedSkinDataReducers,
  isEdit: IsEditReducer,
  userCardDetails: UserCardDetailsReducer,
  TimeOut: TimeOutReducers,
  SpendLimit: SpendLimitReducers
});
export default reducers;
