import {SelectedSkinDataActionType} from "../Constants/SelectedSkinDataActionType";

const initialState = {
	selectedSkinData: [],
};

export const SelectedSkinDataReducers = (state = initialState, action) => {
	switch (action.type) {
	case SelectedSkinDataActionType.SET_SELECTED_SKIN_DATA:
		return {
			...state,
			selectedSkinData: action.payload,
		};
	default:
		return state;
	}
};
