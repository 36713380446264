import React, {useEffect, useRef, useState} from "react";
import {
    FormControl,
    FormSelect,
    FormCheck,
    FormGroup
} from 'react-bootstrap';
import {Link} from "react-router-dom";
import IconTooltip from "../../assets/images/eoro-2024/icon-tooltip.svg";

const LoginScreenComponent = (props) => {

    const {
        isMobile,
        usernameError,
        confirmPassword,
        confirmPasswordError,
        passwordError,
        password,
        setConfirmPassword,
        setPassword,
        userName,
        setUsernameError,
        setUserName,
        selectedTeam,
        setSelectedTeam,
        allTeams,
        passwordIsNotStrongPopover,
        setPasswordIsNotStrongPopover,
        setTermsAndConditionsButtonDisabled,
        termsAndConditionsButtonDisabled,

        setModalMessage,
        setShowCustomModal,
        setModalStatus,
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [usernameAtError, setUsernameAtError] = useState(false);

    const [showUsernamePopover, setShowUsernamePopover] = useState(false);
    const [showPasswordPopover, setShowPasswordPopover] = useState(false);
    const [showConfirmPasswordPopover, setShowConfirmPasswordPopover] = useState(false);

    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const teamYouSupportRef = useRef();
    const Iam18Ref = useRef();

    const handleUsernameInput = (e) => {
        if (usernameError) {
            setUsernameError(false);
        }
        if (!e?.includes("@")) {
            setUserName(e);
            setUsernameAtError(false);
        } else {
            // alert("You cannot inlcude @ in username.");
            setUsernameAtError(true);
            // setShowCustomModal(true);
            // setModalMessage("You cannot include @ in username.");
            // setModalStatus("error");
        }
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const renderMobile = () => {
        return (
            <>
                <div className={"d-flex flex-column gap-1 "}>
                    <div className={`fw-bold font-20 cabin`}>
                        Create Login
                    </div>
                    <div>
                        <div className={"d-flex align-items-center gap-2"}>
                            <img
                                src={IconTooltip}
                                className="img-fluid"
                                alt="icon"
                                height={10}
                                width={10}
                            />
                            <div className={'fw-bold font-12 cabin'}>
                                Usernames must be 6-14 characters long, containing only letters, numbers, and
                                underscores, with no accents or spaces.
                            </div>
                        </div>
                        <div className={"d-flex align-items-center gap-2"}>
                            <img
                                src={IconTooltip}
                                className="img-fluid"
                                alt="icon"
                                height={10}
                                width={10}
                            />
                            <div className={'fw-bold font-12 cabin'}>
                                Your password must contain at least six characters and include at least one uppercase
                                letter, one number and one special character.
                            </div>
                        </div>
                    </div>
                    <div className={"d-flex flex-column align-items-center justify-content-start gap-4 my-2"}>
                        <div className={"d-flex flex-column w-100"}>
                            <FormControl
                                // borderColor={usernameError ? Colors.redOne : Colors.greenTeal}
                                value={userName}
                                type="text"
                                onChange={(e) => handleUsernameInput(e.target.value)}
                                placeholder="Username (6-14 Characters)"
                                autoComplete={"off"}
                            />
                            {usernameAtError &&
                                <>
                                    <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                        You cannot include @ in username.
                                    </div>
                                </>
                            }
                            {usernameError && (
                                <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                    Please enter a valid username.
                                </div>
                            )}
                        </div>
                        <div className={"d-flex flex-column w-100 gap-4"}>
                            <div className={"d-flex flex-column w-100"}>
                                <div className={"d-flex w-100 align-items-center position-relative"}>
                                    <div className={"w-100 "}>
                                        <FormControl
                                            ref={passwordRef}
                                            value={password}
                                            type={showPassword ? "text" : "password"}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div
                                        className="position-absolute end-0 pe-2 fw-bold"
                                        onClick={handleShowPassword}
                                        role={"button"}
                                    >
                                        {showPassword ? "Hide" : "Show"}
                                    </div>
                                </div>
                                {/*{passwordError && (*/}
                                {/*    <div className={'fw-bold font-10 text-losing cabin my-2'}>*/}
                                {/*        Your password must contain at least six characters and include at least one uppercase letter, one number and one special character.*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </div>
                            <div className={"d-flex flex-column w-100"}>
                                <div className={"d-flex w-100 align-items-center position-relative"}>
                                    <div className={"w-100 "}>
                                        <FormControl
                                            ref={confirmPasswordRef}
                                            value={confirmPassword}
                                            type={showConfirmPassword ? "text" : "password"}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            placeholder="Confirm Password"
                                        />
                                    </div>
                                    <div
                                        className="position-absolute end-0 pe-2 fw-bold"
                                        onClick={handleShowConfirmPassword}
                                        role={"button"}
                                    >
                                        {showConfirmPassword ? "Hide" : "Show"}
                                    </div>
                                </div>
                                {passwordError && (
                                    <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                        Passwords do not match.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={"d-flex flex-row align-items-center justify-content-start gap-4 my-2 w-100"}>
                        <div className={`fw-bold font-20 cabin`}>Team You Support</div>
                        <div className={`fw-bold font-16 cabin`}>(Optional)</div>
                    </div>
                    <div className={"d-flex flex-row align-items-center justify-content-start gap-4 w-100"}>
                        <FormSelect
                            value={selectedTeam}
                            onChange={(e) => setSelectedTeam(e.target.value)}
                            className="mb-4"
                        >
                            <option>Select Team</option>
                            {allTeams && allTeams.map((value, index) => (
                                <option key={index} value={value?.goalserve_id}>
                                    {value?.name}
                                </option>
                            ))}
                        </FormSelect>
                    </div>
                    <div className={"w-100"}>
                        <FormGroup className="w-100">
                            <FormCheck
                                type="checkbox"
                                id="termsAndConditionsCheckbox"
                                checked={termsAndConditionsButtonDisabled}
                                onChange={() => setTermsAndConditionsButtonDisabled(!termsAndConditionsButtonDisabled)}
                                label={
                                    <div className={`d-flex align-items-center font-18 cabin flex-wrap`}>
                                        <div>
                                            I am at least 18 years of age and have read, accept and agree to the
                                        </div>
                                        <Link to="/terms-and-conditions"
                                              className={"text-decoration-none skins-link-red ms-1"}>
                                            Terms and Conditions
                                        </Link>
                                        <div>,</div>
                                        <Link to="/game-rules" className={"text-decoration-none skins-link-red ms-1"}>
                                            Rules
                                        </Link>
                                        <div>,</div>
                                        <Link to="/privacy-policy"
                                              className={"text-decoration-none skins-link-red ms-1"}>
                                            Privacy Policy
                                        </Link>
                                        <div>,</div>
                                        <Link to="/cookie-policy"
                                              className={"text-decoration-none skins-link-red mx-1"}>
                                            Cookies Policy
                                        </Link>
                                        <div>and policies relating to</div>
                                        <div className={"skins-link-red mx-1"}>age</div>
                                        <div>and</div>
                                        <Link to="/document-verification"
                                              className={"text-decoration-none skins-link-red ms-1"}>
                                            identity verification.
                                        </Link>

                                    </div>
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
            </>
        );
    }
    const renderDesktop = () => {
        return (
            <>
                <div className={"d-flex flex-column gap-1 "}>
                    <div className={'fw-bold font-20 cabin'}>
                        Create Login
                    </div>
                    <div>
                        <div className={"d-flex align-items-center gap-2"}>
                            <img
                                src={IconTooltip}
                                className="img-fluid"
                                alt="icon"
                                height={10}
                                width={10}
                            />
                            <div className={'fw-bold font-12 cabin'}>
                                Usernames must be 6-14 characters long, containing only letters, numbers, and
                                underscores, with no accents or spaces.
                            </div>
                        </div>
                        <div className={"d-flex align-items-center gap-2"}>
                            <img
                                src={IconTooltip}
                                className="img-fluid"
                                alt="icon"
                                height={10}
                                width={10}
                            />
                            <div className={'fw-bold font-12 cabin'}>
                                Your password must contain at least six characters and include at least one uppercase
                                letter, one number and one special character.
                            </div>
                        </div>
                    </div>
                    <div className={"d-flex flex-row align-items-center justify-content-start gap-4 w-100 mt-1"}>
                        <div className={"d-flex flex-column w-50"}>
                            <FormControl
                                // borderColor={usernameError ? Colors.redOne : Colors.greenTeal}
                                value={userName}
                                type="text"
                                onChange={(e) => handleUsernameInput(e.target.value)}
                                placeholder="Username (6-14 Characters)"
                                autoComplete={"off"}
                            />
                            <div className="error-container">
                                {usernameAtError &&
                                    <>
                                        <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                            You cannot include @ in username.
                                        </div>
                                    </>
                                }
                                {usernameError && (
                                    <div className={'fw-bold font-10 text-losing cabin'}>
                                        Please enter a valid username.
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={"d-flex w-50 gap-4"}>
                            <div className={"d-flex flex-column w-100"}>
                                {/*<FormControl*/}
                                {/*    ref={passwordRef}*/}
                                {/*    // borderColor={passwordError ? Colors.redOne : Colors.greenTeal}*/}
                                {/*    value={password}*/}
                                {/*    type={showPassword ? "text" : "password"}*/}
                                {/*    onChange={(e) => setPassword(e.target.value)}*/}
                                {/*    placeholder="Password"*/}
                                {/*/>*/}
                                <div className={"d-flex w-100 align-items-center position-relative"}>
                                    <div className={"w-100 "}>
                                        <FormControl
                                            ref={passwordRef}
                                            value={password}
                                            type={showPassword ? "text" : "password"}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div
                                        className="position-absolute end-0 pe-2 fw-bold"
                                        onClick={handleShowPassword}
                                        role={"button"}
                                    >
                                        {showPassword ? "Hide" : "Show"}
                                    </div>
                                </div>
                                <div className="error-container">
                                    {/*{passwordError && (*/}
                                    {/*    <div className={'fw-bold font-10 text-losing cabin my-2'}>*/}
                                    {/*        Your password must contain at least six characters and include at least one uppercase letter, one number and one special character.*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                            </div>
                            <div className={"d-flex flex-column w-100"}>
                                <div className={"d-flex w-100 align-items-center position-relative"}>
                                    <div className={"w-100 "}>
                                        <FormControl
                                            ref={confirmPasswordRef}
                                            value={confirmPassword}
                                            type={showConfirmPassword ? "text" : "password"}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            placeholder="Confirm Password"
                                        />
                                    </div>
                                    <div
                                        className="position-absolute end-0 pe-2 fw-bold"
                                        onClick={handleShowConfirmPassword}
                                        role={"button"}
                                    >
                                        {showConfirmPassword ? "Hide" : "Show"}
                                    </div>
                                </div>
                                <div className="error-container">
                                    {passwordError && (
                                        <div className={'fw-bold font-10 text-losing cabin'}>
                                            Passwords do not match.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"d-flex flex-row align-items-center justify-content-start gap-4 w-50 mt-2"}>
                        <div className={"fw-bold font-20 cabin"}>Team You Support</div>
                        <div className={"fw-bold font-16 cabin"}>(Optional)</div>
                    </div>
                    <div className={"d-flex flex-row align-items-center justify-content-start gap-4 w-50"}>
                        <FormSelect
                            value={selectedTeam}
                            onChange={(e) => setSelectedTeam(e.target.value)}
                            // className="mb-4"
                        >
                            <option>Select Team</option>
                            {allTeams && allTeams.map((value, index) => (
                                <option key={index} value={value?.goalserve_id}>
                                    {value?.name}
                                </option>
                            ))}
                        </FormSelect>
                    </div>
                    <div className={"mt-2 px-5"}>
                        <FormGroup className="align-items-center">
                            <FormCheck
                                type="checkbox"
                                id="termsAndConditionsCheckbox"
                                checked={termsAndConditionsButtonDisabled}
                                onChange={() => setTermsAndConditionsButtonDisabled(!termsAndConditionsButtonDisabled)}
                                label={
                                    <div className={'d-flex align-items-center font-18 cabin flex-wrap'}>
                                        <div>
                                            I am at least 18 years of age and have read, accept and agree to the
                                        </div>
                                        <Link to="/terms-and-conditions"
                                              className={"text-decoration-none skins-link-red ms-1"}>
                                            Terms and Conditions
                                        </Link>
                                        <div>,</div>
                                        <Link to="/game-rules" className={"text-decoration-none skins-link-red ms-1"}>
                                            Rules
                                        </Link>
                                        <div>,</div>
                                        <Link to="/privacy-policy"
                                              className={"text-decoration-none skins-link-red ms-1"}>
                                            Privacy Policy
                                        </Link>
                                        <div>,</div>
                                        <Link to="/cookie-policy"
                                              className={"text-decoration-none skins-link-red mx-1"}>
                                            Cookies Policy
                                        </Link>
                                        <div>and policies relating to</div>
                                        <div className={"skins-link-red mx-1"}>age</div>
                                        <div>and</div>
                                        <Link to="/document-verification"
                                              className={"text-decoration-none skins-link-red ms-1"}>
                                            identity verification.
                                        </Link>

                                    </div>
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
            </>
        );
    }
    const renderMain = () => {
        return (
            <>
                {isMobile ? renderMobile() : renderDesktop()}
            </>
        );
    };

    return renderMain();
};

export default LoginScreenComponent;