import SkinsRoutes from "./Routes/SkinsRoutes";
import './assets/styles/sass/fonts.module.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/skins.css';
import './assets/styles/fonts.css';
import './assets/styles/animations.css';
import {useEffect} from "react";
import {SETTINGS} from "./Axios/EndPoints";
import {get} from "./Axios/get";
import {useDispatch, useSelector} from "react-redux";
import {setAppSettings} from "./redux/Actions/AppSettings";

function App() {
    const dispatch = useDispatch();
    const user = useSelector(state => state?.Login);



    const fetchSettings = () => {
        let token = user?.user?.token;
        get(SETTINGS, true, token, null,"").then(res => {
            if (res?.data?.success) {
                let tempSettings = {};
                Object.values(res?.data?.data)?.map(value => {
                    tempSettings[value?.key] = value?.value;
                });
                // console.log("temp settings >>>", tempSettings)
                dispatch(setAppSettings(tempSettings));
            }
        }).catch(error => console.error("Error while getting settings",
            error.response));
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    return <SkinsRoutes/>
}

export default App;
