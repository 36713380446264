export const AuthenticationActionType =
	{
		SET_IMAGE: "SET_IMAGE",
		SET_USER: "SET_USER",
		SET_DISPLAY_NAME: "SET_DISPLAY_NAME",
		SET_USERNAME: "SET_USERNAME",
		SET_PHONE: "SET_PHONE",
		SET_EMAIL: "SET_EMAIL",
		SET_TOKEN: "SET_TOKEN",
		SET_KYC: "SET_KYC",
		SET_KYC_RESPONSE: "SET_KYC_RESPONSE",
		SET_FCM_TOKEN: "SET_FCM_TOKEN",
	};
