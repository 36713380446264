// EuroGameRules
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";

const EuroTermsAndConditions = () => {
    const render = () => {
        return (
            <>
                <div className={'text-center fw-bold font-48 text-dark cabin my-3'} role={'heading'}
                     aria-level={1}>
                    Euro 2024
                </div>
                <p>&nbsp;</p>
                <p><strong>9.8.6</strong><span className={'fw-400'}> Please note that under the Terms of Use you agree with GAMSTOP, your self-exclusion will be activated as soon as possible after your application has been processed.  Whilst GAMSTOP make every effort to do this within 24 hours, this cannot be guaranteed.  However, once the self-exclusion has been successful and is activated on GAMSTOP’s operating systems, the self-exclusion will take effect on our operating systems (albeit you are strongly advised to also inform us of your participation in the GAMSTOP self-exclusion service )</span>
                </p>
                <p><strong>9.8.7</strong><span className={'fw-400'}> To ensure that you get the full benefit of the self-exclusion facility available under the GAMSTOP self-exclusion service, you are advised to also contact the online gambling operators you use to confirm that your application to register with the GAMSTOP self-exclusion service has been successful.  You can contact us by email at the following address: customerservices@communitygaming.co.uk</span>
                </p>
                <p><strong>9.8.8</strong><span className={'fw-400'}> When contacting us to confirm that your application to register with the GAMSTOP self-exclusion service has been successful, please confirm the following information (you are reminded that the information you provide to us and GAMSTOP must be accurate, consistent and complete to ensure you can fully benefit from the GAMSTOP self-exclusion service):</span>
                </p>
                <p><strong>9.8.8.1</strong><span className={'fw-400'}> name;</span>
                </p>
                <p><strong>9.8.8.2</strong><span className={'fw-400'}> online account username;</span>
                </p>
                <p><strong>9.8.8.3</strong><span className={'fw-400'}> address; and</span>
                </p>
                <p><strong>9.8.8.3</strong><span className={'fw-400'}> date of birth.</span>
                </p>
                <p><span className={'fw-400'}> You should also provide us with any postcodes you have previously lived at together with any usernames, email addresses and mobile numbers you currently use or have previously used.</span>
                </p>
                <p><strong>9.8.9</strong><span className={'fw-400'}> By contacting us to confirm that your application to register with the GAMSTOP self-exclusion service has been successful, we will be able to put in place measures and arrangements for the following:</span>
                </p>
                <p><strong>9.8.10</strong><span className={'fw-400'}> Subject to our rights under any terms and conditions you have agreed with us permitting us to withhold any funds from your online accounts the full balance of your online accounts will be returned to you within 10 working days. Any potential returns due from bets placed before self-exclusion will be returned via our standard procedure; and</span>
                </p>
                <p><strong>9.8.11</strong><span
                    className={'fw-400'}> You will not receive from us any direct online marketing materials in relation to our online products, promotions and/or services.</span>
                </p>
                <p><strong>9.8.12</strong><span className={'fw-400'}> After expiry of the minimum exclusion period agreed between you and GAMSTOP, you will be required to contact GAMSTOP direct to arrange for your self-exclusion to be deactivated.  Whilst GAMSTOP make every effort to deactivate your self-exclusion as soon as possible, this cannot be guaranteed.  Please note that we cannot process deactivation on your behalf.  All requests to GAMSTOP for deactivation of your self-exclusion will also be subject to a 24 hour “cooling-off” period.  Once your self-exclusion has been successfully deactivated, you will be able to access your online accounts/open new online accounts with us.  In circumstances where you do not contact GAMSTOP to deactivate your self-exclusion after the period of exclusion you have agreed with GAMSTOP expires, your self-exclusion will continue for a further 7 years, after which the self-exclusion will be deactivated.</span>
                </p>
                <p><strong>9.8.13</strong><span className={'fw-400'}> You agree not to attempt to open new online accounts, log into any of your existing online accounts, or in any other way try to circumvent GAMSTOP’s or our operating systems/mechanisms for the duration of your self-exclusion.   The GAMSTOP self-exclusion service is intended to assist you with responsibly managing your online gambling with us.  As confirmed in GAMSTOP’s Terms of Use, the service is not intended to function as a replacement for willpower, is not “foolproof” and we cannot therefore guarantee that it will always result in you being denied access to your online accounts, or refused when applying to open new online accounts.</span>
                </p>
                <p><strong>9.8.14</strong><span className={'fw-400'}> Having implemented reasonable checks and safeguards to ensure that whilst you are self-excluded under the GAMSTOP self-exclusion service you cannot access an existing online account or open a new online account with us, we cannot be held liable to you or any third party if you are able to continue to enter games via our App or Website</span>
                </p>
                <p><strong>9.8.15</strong><span className={'fw-400'}> In addition, in no circumstances will we be liable to you or any third party if you are able to access your online accounts or open new online accounts during a period of self-exclusion under the GAMSTOP self-exclusion service for reasons outside of our reasonable control.  This will include circumstances where the operating systems utilised by GAMSTOP fail to properly and/or instantaneously identify you and/or your online accounts as subject to self-exclusion when you attempt to use our app or website.</span>
                </p>

                <p id={`euro-complaint`}><strong>10. Complaints procedure and Governing Law and Jurisdiction</strong></p>

                <p><strong>10.1</strong><span className={'fw-400'}> Should there be any claim or dispute arising from a past or current transaction, please contact us customerservices@communitygaming.co.uk as soon as reasonably practical. If, after speaking to customer services we are unable to resolve the query/complaint you may ask it to be referred to a manager or supervisor. A Company manager/supervisor will look into the query/complaint in more detail and contact you with a resolution within 48 hours. You will be given the name and status of the person who your query/complaint has been referred to.</span>
                </p>
                <p><strong>10.2</strong><span className={'fw-400'}> Either party is entitled to refer the dispute/query to an arbiter, such as the Independent Betting Adjudication Service (IBAS), whose decision Community Gaming Ltd will agree to be bound by (save in respect of any manifest error). No dispute/query regarding any entrance fee placed will result in litigation, court action or objection to a bookmaker’s licence or permit (including any remote operator’s licence or personal licence) unless Community Gaming Ltd. fails to implement the decision given by arbitration.</span>
                </p>
                <p><strong>10.3</strong><span className={'fw-400'}> IBAS provides a free-of-charge service and rules on complaints about betting and gaming transactions; but it does not deal with service related problems. IBAS PO Box 62639 London EC3P 3AS Tel: 0207 347 5883 adjudication@ibas-uk.co.uk http://www.ibas-uk.com</span>
                </p>
                <p><strong>10.4</strong><span className={'fw-400'}> You will be given a copy of this complaints procedure on request, or upon making a complaint.</span>
                </p>
                <p><strong>10.5</strong><span className={'fw-400'}> We retain copies of all complaints referred to IBAS and provide such records to the Gambling Commission on a quarterly basis.</span>
                </p>
                <p><strong>10.6</strong><span className={'fw-400'}> These Terms and Conditions and any dispute or claim arising out of or in connection with it or its subject matter, whether of a contractual or non-contractual nature, shall be governed by and construed in accordance with the laws of England and Wales.</span>
                </p>
                <p><strong>10.7</strong><span className={'fw-400'}> By accepting these Terms and Conditions and/or depositing an entry fee and making use of (whether authorised or not) the website and/or mobile Applications provided by Community Gaming Limited, and/or by using, visiting and/or accessing any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or not) of the Website, you irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute which may arise out of or in connection with these Terms and Conditions.</span>
                </p>
                <p>&nbsp;</p>
            </>
        );
    };

    return render();
}


export default EuroTermsAndConditions;
