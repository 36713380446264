import {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import moment from "moment";

import PersonalDetailsSignUpComponent from "../Components/SignUpScreenComponents/PersonalDetailsSignUpComponent";
import ContactInformationSignUpComponent from "../Components/SignUpScreenComponents/ContactInformationSignUpComponent";
import AddressInformationSignUpComponent from "../Components/SignUpScreenComponents/AddressInformationSignUpComponent";
import LoginScreenComponent from "../Components/SignUpScreenComponents/LoginScreenComponent";
import {useDispatch, useSelector} from "react-redux";
import {get} from "../Axios/get";
import {
    APP_LOGS,
    GAMSTOP_AND_USER_CHECK,
    GET_ALL_TEAMS,
    KYC,
    KYC_LOG, REGISTER_CHECK,
    REGISTER_USER,
    STAGING_BASE_URL
} from "../Axios/EndPoints";
import {post} from "../Axios/post";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setIsLogin, setToken, setUser} from "../redux/Actions/LoginAction";
import {
    setDisplayName,
    setEmailRedux,
    setKyc,
    setKycResponse,
    setUserNameRedux
} from "../redux/Actions/AuthenticationAction";
import {setFromPaymentScreen} from "../redux/Actions/FromPaymentScreenAction";
import axios from "axios";
import * as ENDPOINTS from "../Axios/EndPoints";

const DocumentVerification = (props) => {
    const {isMobile} = props;
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navigate = useNavigate();



    const renderMobile = () => {
        return (
            <>
                <div className={'text-center fw-bold font-60 h1 text-white gibson text-uppercase'}>
                    <span className={'font-50'}>Document</span> {' '}
                    <span className={'text-skins-teal font-45'}>Verification</span>
                </div>
                <Container className={"pt-4 pb-5 bg-team-card-grey rounded-my-skins-top"}>
                    <div>DocumentVerification</div>
                </Container>

            </>
        );
    }

    const renderDesktop = () => {
        return (
            <>
                <Row>
                    <div className={'text-center fw-bold font-60 h1 text-white gibson text-uppercase'}>
                        <span className={''}>Document</span> {' '}
                        <span className={'text-skins-teal'}>Verification</span>
                    </div>
                </Row>
                <Container className={"pb-5"}>
                    <div>DocumentVerification</div>
                </Container>
            </>
        );
    }
    const render = () => {
        return (
            <section
            >
                <Helmet>
                    <title>Sportskins - Document Verification</title>
                    <meta name="description" content="Sportskins- Document Verification"/>
                </Helmet>
                {isMobile ? renderMobile() : renderDesktop()}
            </section>
        );
    }

    return render();
}

export default DocumentVerification;
