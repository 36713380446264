import { AuthenticationActionType } from "../Constants/AuthenticationActionType";

export const setUserRedux = (user) => {
	return {
		type: AuthenticationActionType.SET_USER,
		payload: user,
	};
};
export const setDisplayName = (displayName) => {
	return {
		type: AuthenticationActionType.SET_DISPLAY_NAME,
		payload: displayName,
	};
};

export const setUserNameRedux = (userName) => {
	return {
		type: AuthenticationActionType.SET_USERNAME,
		payload: userName,
	};
};

export const setPhone = (phoneNumber) => {
	return {
		type: AuthenticationActionType.SET_PHONE,
		payload: phoneNumber,
	};
};

export const setEmailRedux = (email) => {
	return {
		type: AuthenticationActionType.SET_EMAIL,
		payload: email,
	};
};

export const setToken = (token) => {
	return {
		type: AuthenticationActionType.SET_TOKEN,
		payload: token,
	};
};

export const setFCMToken = (fcmToken) => {
	return {
		type: AuthenticationActionType.SET_FCM_TOKEN,
		payload: fcmToken,
	};
};

export const setKyc = (kyc) => {
	return {
		type: AuthenticationActionType.SET_KYC,
		payload: kyc,
	};
};
export const setKycResponse = (kycResponse) => {
	return {
		type: AuthenticationActionType.SET_KYC_RESPONSE,
		payload: kycResponse,
	};
};
