import {AuthenticationActionType} from "../Constants/AuthenticationActionType";

const initialState = {
	user: null,
	image: "",
	displayName: "",
	userName: "",
	phone: "",
	email: "",
	token: "",
	kyc: "",
	kycResponse: "",
	SET_FCM_TOKEN: "",
};

export const AuthenticationReducers = (state = initialState, action) => {
	switch (action.type) {
	case AuthenticationActionType.SET_USER:
		return {...state, user: action.payload};
	case AuthenticationActionType.SET_IMAGE:
		return {...state, image: action.payload};
	case AuthenticationActionType.SET_DISPLAY_NAME:
		return {...state, displayName: action.payload};
	case AuthenticationActionType.SET_USERNAME:
		return {...state, userName: action.payload};
	case AuthenticationActionType.SET_PHONE:
		return {...state, phone: action.payload};
	case AuthenticationActionType.SET_EMAIL:
		return {...state, email: action.payload};
	case AuthenticationActionType.SET_TOKEN:
		return {...state, token: action.payload};
	case AuthenticationActionType.SET_KYC:
		return {...state, kyc: action.payload};
	case AuthenticationActionType.SET_KYC_RESPONSE:
		return {...state, kycResponse: action.payload};
	case AuthenticationActionType.SET_FCM_TOKEN:
		return {...state, fcmToken: action.payload};
	default:
		return state;
	}
};
