import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Button, Col, FormCheck, FormGroup, Modal, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import leftImage from "../assets/gif/Final.gif";
import {Frames, CardNumber, ExpiryDate, Cvv, CardFrame} from 'frames-react';
import {post} from "../Axios/post";
import LoginModal from "../Components/LoginModal";
import {MAKE_PAYMENT_CHECKOUT, STAGING_BASE_URL,} from "../Axios/EndPoints";
import {setFromPaymentScreen} from "../redux/Actions/FromPaymentScreenAction";
import ButtonLoader from "../Components/ButtonLoader";
import ImageBottomFooter from "../assets/images/eoro-2024/bottom-footer-euro-2024.svg";
import BottomFooter from "../Components/BottomFooter";
import CustomModal from "../Components/ModalComponent/CustomModal";

const Payment = (props) => {
    const {isMobile} = props;
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const store = useSelector(state => state);
    const isLogin = useSelector(state => state.Login.isLogin);
    const user = useSelector(state => state.Login);
    // console.log("user data >>>", user?.user?.user?.kyc)

    const settingsStore = useSelector(state => state?.AppSettings?.appSettings);
    // const PreparedData = store?.PaymentData?.preparedData;
    //
    // console.log("settingsStore?.CHECKOUT_PUBLIC_KEY >>>", settingsStore?.CHECKOUT_PUBLIC_KEY)

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showCustomModal, setShowCustomModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalStatus, setModalStatus] = useState("");

    const [documentVerification, setDocumentVerification] = useState(false);

    // Public api key for checkout
    let publicKey = settingsStore?.CHECKOUT_PUBLIC_KEY;
    // const isSandbox = true;

    useEffect(() => {
        if (!isLogin) {
            setShowLoginModal(true);
        } else {
            // render component api call
        }
    }, [isLogin]);
    useEffect(() => {
        let tempKycCheck = user?.user?.user?.kyc;
        if(!tempKycCheck){
            setDocumentVerification(true)
        }
    }, []);
    const submitRequest = (e) => {
        setLoading(true);
        // console.log("e.token >>>", e.token)
        // console.log("e >>>", e)
        let isStaging = parseInt(params.get('staging'));
        let formData = new FormData();
        formData.append("type", "token");
        formData.append("token", e.token);

        // const tmpData = {
        //     payment_information: {
        //         type: "token",
        //         token: e.token,
        //     },
        // };

        post(
            MAKE_PAYMENT_CHECKOUT,
            formData,
            true,
            store?.Authentication.token,
            dispatch,
            isStaging ? STAGING_BASE_URL : ''
        ).
        then(res => {
            if (res?.data?.success === true) {
                // dispatch(setSelectedTeams([]));
                dispatch(setFromPaymentScreen(false));
                if (res?.data?.data?.three_ds) {
                    window.location.href = res?.data?.data?.redirect_url;
                }else{
                    navigate("/euro-24");
                }
                // dispatch(setPaymentPreparedData([]));
                // dispatch(setSelectedSkinDataAction([]));
                // alert(res?.data?.message);
                // console.log("payment done >>>res? >>>>", res?.data?.data);

            }
        }).
        catch((error) => {
            setLoading(false);
            setShowCustomModal(true);
            setModalMessage(error.response?.data?.message);
            setModalStatus("error");
            console.error("error while submitting payment = ", error?.response?.data?.message)
        });
    };

    const onClickModal = () => {
        setShowCustomModal(false);
        setModalMessage("");
        setModalStatus("");
        navigate("/");

    }

    const documentVerificationModal = () => {
        return (
            <Modal
                centered={true}
                size="md"
                show={documentVerification}
                onHide={() => {
                    // e.preventDefault();
                    setDocumentVerification(false);
                    navigate("/");
                }}
            >
                <Modal.Header
                    className={"border-0"}
                    closeButton
                >
                </Modal.Header>
                <Modal.Body>
                    <div className={"d-flex align-items-center justify-content-center flex-column gap-2 mb-4"}>
                        <img
                            alt={"info pic"}
                            src={require("../assets/images/gamStopCheck.png")}
                            height={150}
                        />
                        <div className={'fw-bold font-20 cabin text-center'}>Alas!</div>
                        <div className={'font-16 cabin text-center'}>
                            Your KYC has failed. Please login to our mobile application to verify your documents and
                            complete KYC.
                        </div>
                        <div className={'font-16 cabin text-center'}>
                            Download our mobile application if you have not done so yet.
                        </div>
                        <div className={"d-flex flex-row gap-3"}>
                            <div className={"border"}><a href={'https://play.google.com/store/apps/details?id=uk.sportskins'}
                                                         target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/home/google_play.png')}
                                    alt={'Home Google Play'}
                                    height={40}
                                />
                            </a></div>
                            <div className={"border"}><a href={'https://apps.apple.com/gb/app/sportskins-football-betting/id1668307911'}
                                                         target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/home/app_store.png')}
                                    alt={'Home App Store'}
                                    height={40}
                                />
                            </a></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const renderPaymentForm = () => {
        return (
            <>
                <Frames
                    config={{
                        // debug: true,
                        // publicKey: "pk_sbox_f4netsprkcienz4n37k5hme56u#",
                        // publicKey: isSandbox ? "pk_sbox_f4netsprkcienz4n37k5hme56u#" : settingsStore?.CHECKOUT_PUBLIC_KEY,
                        publicKey: publicKey,
                        schemeChoice: "true",
                        localization: {
                            cardNumberPlaceholder: 'Card number',
                            expiryMonthPlaceholder: 'MM',
                            expiryYearPlaceholder: 'YY',
                            cvvPlaceholder: 'CVV',
                        },
                        style: {
                            base: {
                                fontSize: '17px',
                            },
                            invalid: {
                                color: 'red', // Invalid input color
                            },
                        },
                    }}
                    cardTokenized={e => {
                        // console.log("e.token here>>>", e)
                        submitRequest(e);
                    }}
                    cardTokenizationFailed={error =>
                        console.error("error tokenizing =", error)
                    }
                >
                    <div className={"d-flex flex-column gap-2 mt-3"}>
                        <div className={"font-13 roboto fw-bold"}>Card Number</div>
                        <CardNumber className={" payment-input ps-3 pe-3 rounded"}/>
                    </div>
                    <div className={"d-flex flex-row my-3 align-items-center justify-content-between gap-3 py-4"}>
                        <div className={"d-flex flex-column gap-2"}>
                            <div className={"font-13 roboto fw-bold"}>Exp. Date</div>
                        <ExpiryDate className={" payment-input ps-3 pe-3 rounded"}/>
                        </div>
                        <div className={"d-flex flex-column gap-2"}>
                            <div className={"font-13 roboto fw-bold"}>Card Number</div>
                        <Cvv className={" payment-input ps-3 pe-3 rounded"}/>
                        </div>
                    </div>
                    <div className={"mb-4 d-flex align-items-center justify-content-end"}>
                        <div className={"font-26 gibson fw-bold"}>Total: </div>
                        <div className={"font-26 gibson fw-bold text-red"}>£50/-</div>
                    </div>
                    <Button
                        className={'btn-skins-red w-100'}
                        onClick={() => {
                            Frames.submitCard();
                            setLoading(true);
                            // console.log("submitted")
                        }}
                    >
                        {
                            loading ? <>
                                <ButtonLoader loading={loading}/>
                            </>:
                                    "Pay Now"

                        }
                    </Button>
                </Frames>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <div className={'text-center fw-bold font-60 h1 text-white gibson text-uppercase'}>
                    <span className={'font-50'}>Payment</span> {' '}
                </div>
                <div className={"bg-team-card-grey rounded-my-skins-top"} style={{display:documentVerification ?"none":""}}>
                    <Container className={"d-flex justify-content-center"}>
                        <div
                            className={"p-4 d-flex align-items-center justify-content-between gap-4"}>
                            <div className={"d-flex flex-column align-items-start justify-content-between w-100 gap-2"}>
                                <div className={"w-100"}>
                                    <img src={leftImage} alt="Image" className="img-fluid w-100 rounded-5"/>
                                </div>
                                {/*<div className={"payment-card-vertical-line"}></div>*/}
                                <div className={"w-100"}>
                                    {renderPaymentForm()}
                                </div>
                            </div>
                        </div>
                    </Container>
                    <div>
                        <img
                            className={"img-fluid"}
                            src={ImageBottomFooter}
                            alt={"image-footer"}
                        />
                    </div>
                    <div className={"pt-3 pb-1"}>
                        <BottomFooter isPayment={true}/>
                    </div>
                </div>

            </>
        );
    }

    const renderDesktop = () => {
        return (
            <>
                <div
                    style={{display:documentVerification ?"none":""}}
                >
                    <Container className={"w-50"}>
                        <div
                            className={"register-desktop p-4 d-flex align-items-center justify-content-between w-100 gap-4"}>
                            <Row
                                // className={"d-flex align-items-start justify-content-between w-100 h-auto "}
                            >
                                <Col className={"col-6 pe-4"}>
                                    <img src={leftImage} alt="Image" className="left-image-payment h-100 w-100"/>
                                </Col>
                                {/*<Col className={"col-1 payment-card-vertical-line"}></Col>*/}
                                <Col
                                    className={"col-6 payment-card-vertical-line"}
                                    // className={"w-50 px-4 d-flex flex-column align-items-start justify-content-center"}
                                >
                                    <div className={"ps-2 h-100 d-flex flex-column justify-content-between"}>
                                        <div className={'text-center w-100 fw-bold font-60 h1 text-blue gibson text-uppercase'}>
                                            <span className={'font-50'}>Payment</span> {' '}
                                        </div>

                                        <div className={"w-100"}>
                                            {renderPaymentForm()}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div>
                        <img
                            className={"img-fluid"}
                            src={ImageBottomFooter}
                            alt={"image-footer"}
                        />
                    </div>
                    <div className={"pt-3 pb-1"}>
                        <BottomFooter/>
                    </div>
                </div>
            </>
        );
    }
    const render = () => {
        return (
            <section
            >
                <Helmet>
                    <title>Sportskins - Payment-2024</title>
                    <meta name="description" content="Sportskins- Payment-2024"/>
                </Helmet>
                <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal}/>
                {isMobile ? renderMobile() : renderDesktop()}

                {
                    showCustomModal &&
                    <CustomModal
                        showModal={showCustomModal}
                        setShowModal={setShowCustomModal}
                        message={modalMessage}
                        modalStatus={modalStatus}
                        onClick={onClickModal}
                    />
                }
                {
                    documentVerification && documentVerificationModal()
                }

            </section>
        );
    }

    return render();
}

export default Payment;
