// EuroGameRules
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";

const EuroGameRules = (props) => {
    const {isMobile} = props;
    const render = () => {
        return (
            <>
                <div className={'text-center fw-bold font-48 text-dark cabin my-3'} role={'heading'}
                     aria-level={1}>
                    Euro 2024
                </div>
                <section className={``}
                         aria-label={'SportSkins Game Rules'}>
                    <Container
                        className={'bg-white mt-0'}>
                        <ol className={`game-rules-ol ${isMobile ? 'm-1' : 'm-5'}`}>
                            <li>
                                <strong>RULES OF THE GAMES</strong>
                                <ol>
                                    <li data-level="5.1">
                                        A player must register to play on either the 'Sportskins' website or Mobile
                                        Application.
                                        <ol>
                                            <li data-level="5.1.1">
                                                The cost of entry is £50.00
                                            </li>
                                            <li data-level="5.1.2">
                                                Maximum ONE entry per person
                                            </li>
                                            <li data-level="5.1.3">
                                                Prior to the deadline for the opening game of the tournament a player
                                                must select ONE ‘Golden Team’. Any points that the ‘Golden Team’ accrues
                                                on each fixture are doubled for as long as that team is in the
                                                competition. If your ‘Golden Team’ gets eliminated from the competition
                                                you MAY NOT select another.
                                            </li>
                                            <li data-level="5.1.4">
                                                Prior to the deadline for the opening game of the tournament a player
                                                must fill in the ‘Tie-Break’ question, which is; What will be the total
                                                number of goals scored in the whole tournament, excluding Penalty
                                                Shoot-outs?
                                            </li>
                                            <li data-level="5.1.5">
                                                Prior to the deadline for the opening game of the tournament a player
                                                must select which team he/she thinks will finish 1st(Top) in each of the
                                                6 groups and which Team will finish 4th (Bottom) in each of the six
                                                groups.
                                            </li>
                                            <li data-level="5.1.6">
                                                The tournament is divided into FIVE separate competitions, they are:
                                                <div className={"my-2 mx-3"}>
                                                    Game 1- This consists of the first 12 games in the Group Stages.
                                                    <br/>
                                                    Game 2- This consists of the second 12 games in the Group Stages.
                                                    <br/>
                                                    Game 3- This consists of the third 12 games of the Group Stages.
                                                    <br/>
                                                    Game 4- This consists of the 15 games that make up the Knock-Out
                                                    Stages. (8 round of 16 games, 4 Quarter Final games, 2 Semi-Final
                                                    games and 1 Final Game)
                                                    <br/>
                                                    Game 5- The Overall Champion.
                                                </div>
                                            </li>
                                            <li data-level="5.1.7">
                                                Each of the first three games will have a pre set deadline. A player
                                                must fill in his/her score predictions for all of the fixtures that are
                                                relevant for that game day prior to that deadline. If a player does not
                                                enter a prediction for any fixture for some reason then a default score
                                                of 3-3 will be used. The fixtures for Games 1,2 and 3 are already
                                                published and a player may, if they wish, fill in all 3 games in
                                                advance, a player will be able to edit any fixture prior to the relevant
                                                deadline. Game 4 will have 4 different deadlines taking into account
                                                each knockout stage and the fixtures for these will be posted as soon as
                                                they are known.
                                            </li>
                                            <li data-level="5.1.8">
                                                For Games 1,2 and 3 a player must select ONE ‘Joker’ fixture for each
                                                Game. Any points gained on that fixture will be doubled. For Game 4 the
                                                joker is only available for the Round of 16 games.
                                            </li>
                                            <li data-level="5.1.9">
                                                Points are accumulated from the fixtures in each Game (see the scoring
                                                section) and there are prizes for the Top 3 and ties. The scores get
                                                reset to zero prior to each of the 4 games, so everybody has the same
                                                chance of winning however the points accumulated for each game are
                                                carried forward and the player with the most points after the four games
                                                is the overall winner. There are prizes for the top 3 and ties in the
                                                overall competition too. (see Prizes section)
                                            </li>
                                            <li data-level="5.1.10">
                                                All score predictions are based on the final score after 90 minutes(plus
                                                added time) only and do not include Extra Time or penalty shoot outs.
                                            </li>
                                            <li data-level="5.1.11">
                                                If for any reason, a game is postponed and not completed prior to the
                                                end of the Game Day, then the score at the time of the postponement will
                                                be taken as the final score.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-level="5.2">
                                Community Gaming Ltd, if necessary to do so, reserve the right to suspend, amend, void
                                or even cancel a game. This may be necessary for reasons beyond our reasonable control
                                including, but not limited to strike, labour dispute, illness, act of God, natural
                                disaster, adverse weather conditions, war, riot, accident, public health concern in
                                relation to potential infection, epidemic or pandemic, malicious damage, fire, flood
                                and/or storm, compliance with law or governmental order, rule, regulation or direction,
                                breakdown of plant, machinery or transportation. Community Gaming Ltd are not
                                responsible for any loss or damage caused to you as a result of any of these
                                occurrences.
                            </li>
                        </ol>
                    </Container>
                </section>
            </>
        );
    };

    return render();
}


EuroGameRules.propTypes = {
    isMobile: PropTypes.bool.isRequired
};
export default EuroGameRules;
