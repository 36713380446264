import { SpendLimitActionType } from "../Constants/SpendLimitActionType";

const initialState = {
	spendLimitData: {},
};

export const SpendLimitReducers = (state = initialState, action) => {
	switch (action.type) {
	case SpendLimitActionType.SET_SPEND_LIMIT:
		return {
			...state,
			spendLimitData: action.payload,
		};
	default:
		return state;
	}
};
