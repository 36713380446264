import React, {useEffect, useRef, useState} from "react";
import {FormControl, InputGroup, FormSelect, Button, Modal} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {GoogleMapsKey} from "../../Constants/ApiKey";
import Geocoder from "react-native-geocoding";
import {GET_ADDRESS_DETAILS, GET_POSTCODE} from "../../Axios/EndPoints";
import axios from "axios";
import SmallLoader from "../SmallLoader";

const AddressInformationSignUpComponent = (props) => {
    const {
        isMobile,
        country,
        setCountry,
        county,
        setCounty,
        houseNumber,
        postcode,
        setHouseNumber,
        setPostcode,
        townCity,
        setTownCity,
        firstLineAddress,
        setFirstLineAddress,
        addressLineOne,
        setAddressLineOne,
        addressLineTwo,
        setAddressLineTwo,
        addressLineThree,
        setAddressLineThree,
        setAddressLineFour,
        flatNumber,
        setFlatNumber,
        setFormattedAddress
    } = props;
    const [selectedAddress, setSelectedAddress] = useState("");
    const [location, setLocation] = useState([]);
    const [loading, setLoading] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [showRestOfAddressFields, setShowRestOfAddressFields] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [inputFieldLoading, setInputFieldLoading] = useState(false);
    const settingStore = useSelector(state => state?.AppSettings?.appSettings);

    const postCodeRef = useRef();
    const addressLine1Ref = useRef();
    const addressLine2Ref = useRef();
    const addressLine3Ref = useRef();
    const addressLine4Ref = useRef();
    const cityRef = useRef();
    const countyRef = useRef();
    const countryRef = useRef();

    useEffect(() => {
        Geocoder.init(GoogleMapsKey);
    }, []);

    useEffect(() => {
    }, [modalVisible]);

    useEffect(() => {

        if (selectedAddress && selectedAddress !== "") {

            // console.log("selectedAddress =",selectedAddress);
            setHouseNumber(
                selectedAddress?.components[0]?.long_name || "",
            );
            setFirstLineAddress(
                selectedAddress?.components[1]?.long_name || "",
            );
            setTownCity(selectedAddress?.components[2]?.long_name || "");
            setCounty(selectedAddress?.components[3]?.long_name || "");
            setCountry(selectedAddress?.components[4]?.long_name || "");
        }
    }, [selectedAddress]);

    const handleUserSelectedAddress = (item) => {
        const replaceID = GET_ADDRESS_DETAILS.replace("__id__", item?.id);
        const URL_WITH_POSTCODE_AND_API_KEY = replaceID.replace("__apikey__", settingStore?.GET_ADDRESS_API_KEY || "bA8DjHZCj0q2Z2qI1NFTNw38899");

        axios.get(URL_WITH_POSTCODE_AND_API_KEY).then(res => {
            // console.log("res?.data?. >>>>> ", res?.data);

            // console.log("formatted_address -",formattedAddress);
            setFormattedAddress(res?.data);
            setHouseNumber(res?.data?.building_number === "" ? res?.data?.line_1 : res?.data?.building_number);
            setAddressLineOne(res?.data?.line_1 || "");
            setAddressLineTwo(res?.data?.line_2 || "");
            setAddressLineThree(res?.data?.line_3 || "");
            setAddressLineFour(res?.data?.line_4 || "");
            setTownCity(res?.data?.town_or_city);
            setCounty(res?.data?.county);
            setCountry(res?.data?.country);
            setInputFieldLoading(false);
        }).catch(error => {
            console.log("error in find address 2: ", error);
            setInputFieldLoading(false);
        });

        setModalVisible(!modalVisible);
        setShowRestOfAddressFields(!showRestOfAddressFields);
    };

    const renderItem = (item) => {
        return (
            <div>
                <div role={"button"} onClick={() => handleUserSelectedAddress(item)}>
                    <span className={'font-18 text-blue cabin'}>{item?.address}</span>
                </div>
                <hr/>
            </div>
        );
    };

    const findAddress = (postCode) => {
        const replacePostcode = GET_POSTCODE.replace("__postcode__", postCode);
        const URL_WITH_POSTCODE_AND_API_KEY = replacePostcode.replace("__apikey__", settingStore?.GET_ADDRESS_API_KEY || "bA8DjHZCj0q2Z2qI1NFTNw38899");
        // console.log("URL_WITH_POSTCODE_AND_API_KEY: ", URL_WITH_POSTCODE_AND_API_KEY);
        axios.get(URL_WITH_POSTCODE_AND_API_KEY).then(res => {
            setLocation(res?.data?.suggestions);
            setModalVisible(true);
            setModalLoading(false);
            setLoading(false);
        }).catch(error => {
            setModalLoading(false);
            console.log("error in find address 3: ", error?.response?.data);
            setInputFieldLoading(false);
        });
    };

    const findButtonClick = () => {
        // setLoading(true);
        findAddress(postcode);
        setModalVisible(true);
        setModalLoading(true);
        setInputFieldLoading(true);
    };

    const renderMobile = () => {
        return (
            <>
                <div className={"d-flex flex-column gap-1"}>
                    <div className={`fw-bold font-20 cabin`}>
                        Address
                    </div>
                    <div className={"d-flex flex-column align-items-center justify-content-start gap-2 my-2"}>
                        <div className={"d-flex flex-column w-100"}>
                            <FormControl
                                value={houseNumber}
                                type="text"
                                onChange={(e) => setHouseNumber(e.target.value)}
                                placeholder="House/Flat Number/Name"
                            />
                        </div>
                        <div className={"d-flex flex-column w-100"}>
                            <FormControl
                                ref={postCodeRef}
                                value={postcode}
                                type="text"
                                onChange={(e) => {
                                    showRestOfAddressFields && setShowRestOfAddressFields(false)
                                    setLoading(false);
                                    setPostcode(e.target.value);
                                }}
                                placeholder="Postcode"
                            />
                        </div>
                    </div>
                    {
                        !showRestOfAddressFields &&
                        <Button className={'btn-skins-red'} size={'lg'} onClick={findButtonClick}>
                            {loading ?
                                <><SmallLoader loading={loading}/></>
                                :
                                <span>
                                    Find Address
                                </span>
                            }
                        </Button>
                    }
                    <div>
                        <Modal
                            backdrop="static"
                            size="sm"
                            show={modalVisible} onHide={() => setModalVisible(false)}
                            scrollable
                        >
                            <Modal.Header
                                // style={{ backgroundColor: Colors.darkBlue, borderColor: Colors.darkBlue }}
                                className={"bg-modal-header"}
                                // closeButton
                            >
                                <Modal.Title className={'fw-bold font-20 cabin text-white'}>
                                    Choose your address
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {modalLoading ? (
                                    <>
                                        <div className={"bg-dark"}>
                                            <SmallLoader loading={loading}/>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <div
                                                className={'font-18 text-blue cabin'}
                                                role={"button"}
                                                onClick={() => {
                                                    setModalVisible(false);
                                                    setShowRestOfAddressFields(true);
                                                    setLoading(false);
                                                    setInputFieldLoading(false);
                                                }}
                                            >
                                                Enter Field Manually
                                            </div>
                                            <hr/>
                                        </div>
                                        <div>
                                            {location.length > 0 && location.map((value) => renderItem(value))}
                                        </div>
                                    </>
                                )}
                            </Modal.Body>
                        </Modal>
                    </div>
                    {
                        inputFieldLoading ?
                            <SmallLoader loading={inputFieldLoading}/>
                            :
                            <>
                                {
                                    showRestOfAddressFields &&
                                    <>
                                        <div className={"d-flex flex-column w-100 mt-2 gap-2"}>
                                            <FormControl
                                                ref={addressLine1Ref}
                                                value={addressLineOne}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineOne(e.target.value);
                                                }}
                                                placeholder="Address Line 1"
                                            />
                                            <FormControl
                                                ref={addressLine2Ref}
                                                value={addressLineTwo}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineTwo(e.target.value);
                                                }}
                                                placeholder="Address Line 2 (Optional)"
                                            />
                                            <FormControl
                                                ref={addressLine3Ref}
                                                value={addressLineThree}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineThree(e.target.value);
                                                }}
                                                placeholder="Address Line 3 (Optional)"
                                            />
                                            <FormControl
                                                ref={addressLine4Ref}
                                                value={setAddressLineFour}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineFour(e.target.value);
                                                }}
                                                placeholder="Address Line 4 (Optional)"
                                            />
                                            <FormControl
                                                ref={cityRef}
                                                value={townCity}
                                                type="text"
                                                onChange={(e) => {
                                                    setTownCity(e.target.value);
                                                }}
                                                placeholder="Town/City"
                                            />
                                            <FormControl
                                                ref={countyRef}
                                                value={county}
                                                type="text"
                                                onChange={(e) => {
                                                    setCounty(e.target.value);
                                                }}
                                                placeholder="Country"
                                            />
                                            <FormControl
                                                ref={countryRef}
                                                value={country}
                                                type="text"
                                                onChange={(e) => {
                                                    setCountry(e.target.value);
                                                }}
                                                placeholder="Country"
                                            />
                                        </div>
                                    </>
                                }
                            </>
                    }
                </div>
            </>
        );
    }
    const renderDesktop = () => {
        return (
            <>
                <div className={"d-flex flex-column gap-1 "}>
                    <div className={'fw-bold font-20 cabin'}>
                        Address
                    </div>
                    <div className={"d-flex align-items-center justify-content-start gap-4 my-2"}>
                       <div className={"w-75 d-flex gap-4"}>
                           <div className={"d-flex flex-column w-100"}>
                               <FormControl
                                   value={houseNumber}
                                   type="text"
                                   onChange={(e) => setHouseNumber(e.target.value)}
                                   placeholder="House/Flat Number/Name"
                               />
                           </div>
                           <div className={"d-flex flex-column w-100"}>
                               <FormControl
                                   ref={postCodeRef}
                                   value={postcode}
                                   type="text"
                                   onChange={(e) => {
                                       showRestOfAddressFields && setShowRestOfAddressFields(false)
                                       setLoading(false);
                                       setPostcode(e.target.value);
                                   }}
                                   placeholder="Postcode"
                               />
                           </div>
                       </div>
                        <div className={"w-25"}>
                            {
                                !showRestOfAddressFields &&
                                <Button className={'btn-skins-red w-100 p-1'} size={'lg'} onClick={findButtonClick}>
                                    {loading ?
                                        <><SmallLoader loading={loading}/></>
                                        :
                                        <span>
                                    Find Address
                                </span>
                                    }
                                </Button>
                            }
                        </div>

                    </div>

                    <div>
                        <Modal
                            backdrop="static"
                            size="lg"
                            show={modalVisible} onHide={() => setModalVisible(false)}
                            scrollable
                        >
                            <Modal.Header
                                // style={{ backgroundColor: Colors.darkBlue, borderColor: Colors.darkBlue }}
                                className={"bg-modal-header"}
                                closeButton
                            >
                                <Modal.Title className={'fw-bold font-20 cabin text-white'}>
                                    Choose your address
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {modalLoading ? (
                                    <>
                                        <div className={"bg-dark"}>
                                            <SmallLoader loading={loading}/>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <div
                                                className={'font-18 text-blue cabin'}
                                                role={"button"}
                                                onClick={() => {
                                                    setModalVisible(false);
                                                    setShowRestOfAddressFields(true);
                                                    setLoading(false);
                                                    setInputFieldLoading(false);
                                                }}
                                            >
                                                Enter Field Manually
                                            </div>
                                            <hr/>
                                        </div>
                                        <div>
                                            {location.length > 0 && location.map((value) => renderItem(value))}
                                        </div>
                                    </>
                                )}
                            </Modal.Body>
                        </Modal>
                    </div>
                    {
                        inputFieldLoading ?
                            <SmallLoader loading={inputFieldLoading}/>
                            :
                            <>
                                {
                                    showRestOfAddressFields &&
                                    <>
                                        <div className={"d-flex flex-row w-100 mt-2 gap-2"}>
                                            <FormControl
                                                ref={addressLine1Ref}
                                                value={addressLineOne}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineOne(e.target.value);
                                                }}
                                                placeholder="Address Line 1"
                                            />
                                            <FormControl
                                                ref={addressLine2Ref}
                                                value={addressLineTwo}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineTwo(e.target.value);
                                                }}
                                                placeholder="Address Line 2 (Optional)"
                                            />
                                            <FormControl
                                                ref={addressLine3Ref}
                                                value={addressLineThree}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineThree(e.target.value);
                                                }}
                                                placeholder="Address Line 3 (Optional)"
                                            />

                                        </div>
                                        <div className={"d-flex flex-row w-100 mt-2 gap-2"}>
                                            <FormControl
                                                ref={addressLine4Ref}
                                                value={setAddressLineFour}
                                                type="text"
                                                onChange={(e) => {
                                                    setAddressLineFour(e.target.value);
                                                }}
                                                placeholder="Address Line 4 (Optional)"
                                            />
                                            <FormControl
                                                ref={cityRef}
                                                value={townCity}
                                                type="text"
                                                onChange={(e) => {
                                                    setTownCity(e.target.value);
                                                }}
                                                placeholder="Town/City"
                                            />
                                            <FormControl
                                                ref={countyRef}
                                                value={county}
                                                type="text"
                                                onChange={(e) => {
                                                    setCounty(e.target.value);
                                                }}
                                                placeholder="Country"
                                            />
                                            <FormControl
                                                ref={countryRef}
                                                value={country}
                                                type="text"
                                                onChange={(e) => {
                                                    setCountry(e.target.value);
                                                }}
                                                placeholder="Country"
                                            />
                                        </div>
                                    </>
                                }
                            </>
                    }
                </div>
            </>
        );
    }
    const renderMain = () => {
        return (
            <>
                {isMobile ? renderMobile() : renderDesktop()}
            </>
        );
    };

    return renderMain();
};

export default AddressInformationSignUpComponent;