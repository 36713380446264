import PropTypes from "prop-types";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import SkinsSelect from "../Components/BlogComponents/SkinsSelect";
import {useEffect, useState} from "react";
import {get} from "../Axios/get";
import {BLOG_BY_SKIN_WEEK_TYPE, BLOG_BY_SLUG, LATEST_BLOG_BY_SKIN, SKINS, STAGING_BASE_URL} from "../Axios/EndPoints";
import WeeksList from "../Components/BlogComponents/WeeksList";
import TypesList from "../Components/BlogComponents/TypesList";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

const Blogs = (props) => {
    const {isMobile} = props;
    const {slug} = useParams();
    const navigate = useNavigate();

    const [liveSkins, setLiveSkins] = useState([]);
    const [historySkins, setHistorySkins] = useState([]);
    const [blog, setBlog] = useState({});
    const [blogLoading, setBlogLoading] = useState(false);

    const [selectedSkinId, setSelectedSkinId] = useState(0);
    const [selectedSkin, setSelectedSkin] = useState({});
    const [selectedWeekId, setSelectedWeekId] = useState(0);
    const [selectedTypeId, setSelectedTypeId] = useState(0);
    const [selectionChanged, setSelectionChanged] = useState(false);

    const fetchLiveSkins = (type) => {
        get(SKINS + type, false, '', null, type === 'live1' ? STAGING_BASE_URL : '')
            .then((response) => {
                let data = response?.data?.data;
                setLiveSkins(data);
                setSelectedSkinId(data[0].id);
                setSelectedSkin(data[0]);
            })
            .catch((error) => {
                console.error('blog live skins fetch error', error.response);
            });
    }

    const fetchHistorySkins = (type) => {
        get(SKINS + type, false, '', null, type === 'live' ? STAGING_BASE_URL : '')
            .then((response) => {
                let data = response?.data?.data;
                setHistorySkins(data);
            })
            .catch((error) => {
                console.error('blog history skins fetch error', error.response);
            });
    }

    const fetchBlog = (typeId) => {
        setBlogLoading(true);
        get(`${BLOG_BY_SKIN_WEEK_TYPE}/${selectedSkinId}/${selectedWeekId}/${typeId}`)
            .then((response) => {
                let data = response?.data?.data;
                setBlog(data);
                // navigate(`/blog/${data?.slug}`);
                window.history.replaceState({}, null, `/blog/${data?.slug}`);
                setBlogLoading(false);
            })
            .catch((error) => {
                setBlogLoading(false);
                window.history.replaceState({}, null, `/blog`);
                console.error('blog fetch error', error.response);
            });
    }

    const fetchBlogBySlug = () => {
        setBlogLoading(true);
        get(`${BLOG_BY_SLUG}/${slug}`)
            .then((response) => {
                let data = response?.data?.data;
                setSelectedWeekId(data?.week?.id);
                setBlog(data);
                // document.getElementById(`dd_item_${data?.skin?.id}`).trigger('click');
                setSelectedSkinId(data?.skin?.id);
                setSelectedSkin(data?.skin);
                setSelectedTypeId(data?.type_id);
                setBlogLoading(false);
            })
            .catch((error) => {
                setBlogLoading(false);
                console.error('blog slug fetch error', error?.response || error);
            });
    }

    const fetchLatestBlog = async (skinId) => {
        setBlogLoading(true);
        let endpoint = LATEST_BLOG_BY_SKIN;
        endpoint = endpoint.replace('__SKIN_ID__', skinId);
        let data = {};
        await get(endpoint)
            .then((response) => {
                data = response?.data?.data;
                setSelectedWeekId(data?.week?.id);
                setBlog(data);
                setSelectedSkinId(data?.skin?.id);
                setSelectedSkin(data?.skin);
                setSelectedTypeId(data?.type_id);
                setBlogLoading(false);
            })
            .catch((error) => {
                setBlogLoading(false);
                console.error('blog slug fetch error', error?.response || error);
            });
        return data;
    }

    useEffect(() => {
        fetchLiveSkins('in_progress');
        fetchHistorySkins('history');
    }, []);

    useEffect(() => {
    }, [liveSkins, historySkins]);

    useEffect(() => {
        setSelectionChanged(!selectionChanged);
        setSelectedWeekId(blog?.week?.id || selectedWeekId);
        if (slug === undefined) {
            if (selectedSkinId > 0) {
                fetchLatestBlog(selectedSkinId).then((data) => {
                    navigate(`/blog/${data?.slug}`);
                });
            }
        }
    }, [selectedSkinId, selectedSkin]);

    useEffect(() => {
        setSelectionChanged(!selectionChanged);
    }, [selectedWeekId]);

    useEffect(() => {
        if (slug !== undefined) {
            fetchBlogBySlug();
        } else {
            setLiveSkins([]);
            setHistorySkins([]);
            setSelectedSkin({});
            setSelectedSkinId(0);
            setSelectedWeekId(0);
            setSelectedTypeId(0);
            setBlog({});
            setSelectionChanged(!selectionChanged);
        }
    }, [slug]);
    const handleTypeClick = (event) => {
        setBlogLoading(true);
        setBlog({});
        let typeId = event.target.dataset.id;
        fetchBlog(typeId);
    }

    const renderMobile = () => {
        return (
            <div className={'w-100 bg-skins-white rounded-my-skins-top pt-5 mt-5 pb-5'}>
                <Helmet>
                    <title>Sportskins - Blog</title>
                    <meta name="description" content="Sportskins- Blog"/>
                </Helmet>
                <Container>
                    <Row>
                        <div className={'w-fit mb-2'}>
                            <SkinsSelect isMobile={isMobile} skins={liveSkins} type={'live'}
                                         setSelectedSkinId={setSelectedSkinId}
                                         setSelectedSkin={setSelectedSkin}
                                         setSelectedWeekId={setSelectedWeekId}
                                         setSelectedTypeId={setSelectedTypeId}
                            />
                        </div>
                        <div className={'w-fit text-start'}>
                            <SkinsSelect isMobile={isMobile} skins={historySkins} type={'closed'}
                                         setSelectedSkinId={setSelectedSkinId}
                                         setSelectedSkin={setSelectedSkin}
                                         setSelectedWeekId={setSelectedWeekId}
                                         setSelectedTypeId={setSelectedTypeId}
                            />
                        </div>
                    </Row>
                    {
                        selectedSkinId &&
                        <div className={'mb-5'}>
                            <Row className={'mt-4 align-items-center'}>
                                <Col className={'text-start fw-bold gibson font-20 text-black text-nowrap'}>
                                    {selectedSkin?.name}
                                </Col>
                                <Col className={'d-flex justify-content-end align-items-start'}>
                                    <span
                                        className={`roboto font-12 text-white ${selectedSkin?.status === 'Closed' ? 'bg-skins-losing' : 'bg-skins-winning'} rounded-my-skins ps-3 pe-3 pt-2 pb-2 me-4 text-uppercase`}>
                                        {selectedSkin?.status === 'Closed' ? 'Finished' : 'Live'}
                                    </span>
                                    <span className={'fw-bold font-20 bebas text-black'}>
                                        {Intl.NumberFormat("en-GB", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 0,
                                            style: 'currency',
                                            currency: 'GBP'
                                        }).format(selectedSkin?.jackpot)}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <WeeksList isMobile={isMobile} skinId={selectedSkinId} weekId={selectedWeekId}
                                           setSelectedWeek={setSelectedWeekId}
                                           setSelectedWeekId={setSelectedWeekId}
                                           setSelectedTypeId={setSelectedTypeId}
                                />
                            </Row>
                            <Row>
                                <TypesList isMobile={isMobile} handleTypeClick={handleTypeClick}
                                           selectedTypeId={selectedTypeId}
                                           selectionChanged={selectionChanged}/>
                            </Row>
                        </div>
                    }
                    {
                        blogLoading &&
                        <SkeletonTheme baseColor="#ece7ee" highlightColor="#B1B1B1">
                            <p>
                                <Skeleton count={10} className={'p-2 m-2'}/>
                            </p>
                        </SkeletonTheme>
                    }
                    <div>
                        <div>
                            <h1 className={'font-30 gibson text-black'}>
                                {blog?.skin?.name !== blog?.title ? blog?.title : ''}
                            </h1>
                        </div>
                        <div className={'w-100 d-flex justify-content-center'}>
                            {
                                blog?.image !== '' &&
                                <img src={blog?.image} alt={blog?.title} className={'img-fluid text-center'}/>
                            }
                        </div>
                        <FroalaEditorView
                            model={blog?.content}
                        />
                        <div className={'d-flex justify-content-center mb-5'}>
                            {
                                blog?.downloads !== undefined && blog?.downloads !== '' &&
                                <a href={blog?.downloads}
                                   className={'btn btn-skins-red text-white pt-4 pb-4 ps-4 pe-4'}>
                                    Download Picks History
                                </a>
                            }
                        </div>
                        {/*<div style={{visibility: 'hidden'}}>
                            {
                                blog?.image !== '' &&
                                <img src={blog?.image} alt={blog?.title} className={'img-fluid text-center'}/>
                            }
                        </div>*/}
                    </div>
                </Container>

            </div>
        );
    }

    const renderDesktop = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - Blog</title>
                    <meta name="description" content="Sportskins- Blog"/>
                </Helmet>
                <Container>
                    <Row>
                        <div className={'w-fit mb-2'}>
                            <SkinsSelect isMobile={isMobile} skins={liveSkins} type={'live'}
                                         setSelectedSkinId={setSelectedSkinId}
                                         setSelectedSkin={setSelectedSkin}
                                         setSelectedWeekId={setSelectedWeekId}
                                         setSelectedTypeId={setSelectedTypeId}
                            />
                        </div>
                        <div className={'w-fit text-start'}>
                            <SkinsSelect isMobile={isMobile} skins={historySkins} type={'closed'}
                                         setSelectedSkinId={setSelectedSkinId}
                                         setSelectedSkin={setSelectedSkin}
                                         setSelectedWeekId={setSelectedWeekId}
                                         setSelectedTypeId={setSelectedTypeId}
                            />
                        </div>
                    </Row>
                    {
                        selectedSkinId &&
                        <div className={'mb-5'}>
                            <Row className={'mt-4 align-items-center'}>
                                <Col className={'text-start fw-bold gibson font-60 text-white text-nowrap'}>
                                    {selectedSkin?.name}
                                </Col>
                                <Col className={'d-flex justify-content-end align-items-start'}>
                                    <span
                                        className={`roboto font-20 text-white ${selectedSkin?.status === 'Closed' ? 'bg-skins-losing' : 'bg-skins-winning'} rounded-my-skins ps-4 pe-4 pt-3 pb-3 me-4 text-uppercase`}>
                                        {selectedSkin?.status === 'Closed' ? 'Finished' : 'Live'}
                                    </span>
                                    <span className={'fw-bold font-43 bebas text-white'}>
                                        {Intl.NumberFormat("en-GB", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 0,
                                            style: 'currency',
                                            currency: 'GBP'
                                        }).format(selectedSkin?.jackpot)}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <WeeksList isMobile={isMobile} skinId={selectedSkinId} weekId={selectedWeekId}
                                           setSelectedWeek={setSelectedWeekId}
                                           setSelectedWeekId={setSelectedWeekId}
                                           setSelectedTypeId={setSelectedTypeId}
                                />
                            </Row>
                            <Row>
                                <TypesList isMobile={isMobile} handleTypeClick={handleTypeClick}
                                           selectedTypeId={selectedTypeId}
                                           selectionChanged={selectionChanged}/>
                            </Row>
                        </div>
                    }
                    {
                        blogLoading &&
                        <SkeletonTheme baseColor="#ece7ee" highlightColor="#B1B1B1">
                            <p>
                                <Skeleton count={10} className={'p-2 m-2'}/>
                            </p>
                        </SkeletonTheme>
                    }
                    <div className={'text-white'}>
                        <div>
                            <h1 className={'font-40 gibson'}>
                                {blog?.skin?.name !== blog?.title ? blog?.title : ''}
                            </h1>
                        </div>
                        <div className={'w-100 d-flex justify-content-center'}>
                            {
                                blog?.image !== '' &&
                                <img src={blog?.image} alt={blog?.title} className={'img-fluid'}/>
                            }
                        </div>

                        <FroalaEditorView
                            model={blog?.content}
                        />
                        <div className={'d-flex justify-content-center mb-5'}>
                            {
                                blog?.downloads !== undefined && blog?.downloads !== '' &&
                                <a href={blog?.downloads}
                                   className={'btn btn-skins-red text-white pt-2 pb-2 ps-4 pe-4'}>
                                    Download Picks History
                                </a>
                            }
                        </div>
                    </div>

                </Container>
            </div>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

Blogs.propTypes = {
    isMobile: PropTypes.bool.isRequired,
}

export default Blogs;
