import React, {useRef, useState} from "react";
import {FormControl, InputGroup, FormSelect} from 'react-bootstrap';

const ContactInformationSignUpComponent = (props) => {
    const {
        isMobile,
        email,
        confirmEmail,
        setConfirmEmail,
        mobile,
        countryCode,
        setCountryCode,
        setEmail,
        setMobile,
        mobileError,
        setMobileError,
        emailError,
        setEmailError
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [confirmEmailError, setConfirmEmailError] = useState(false);

    const emailRef = useRef(null);
    const confirmEmailRef = useRef();
    const mobileNumberRef = useRef();

    const handleEmailInput = (e) => {
        if (e !== confirmEmail) {
            setConfirmEmailError(true);
        } else {
            setConfirmEmailError(false);
        }
        setEmail(e);
    };

    const handleConfirmEmailInput = (e) => {
        if (e !== email) {
            setConfirmEmailError(true);
        } else {
            setConfirmEmailError(false);
        }
        setConfirmEmail(e);
    };
    const handleMobileInput = (e) => {
        // Remove non-digit characters
        const cleanedMobile = e.replace(/\D/g, '');
        setMobile(cleanedMobile);
    };
    const renderMobile = () => {
        return (
            <>
                <div className={"d-flex flex-column gap-1 gap-2"}>
                    <div className={`fw-bold font-20 cabin`}>
                        Contact Information
                    </div>
                        <div className={"d-flex flex-column w-100"}>
                            <FormControl
                                value={email}
                                type="email"
                                onChange={(e) => handleEmailInput(e.target.value)}
                                placeholder="Email address"
                                ref={emailRef}
                            />
                            {emailError && (
                                <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                    Please enter a valid email address.
                                </div>
                            )}
                        </div>
                        <div className={"d-flex flex-column w-100"}>
                            <FormControl
                                value={confirmEmail}
                                type="email"
                                onChange={(e) => handleConfirmEmailInput(e.target.value)}
                                placeholder="Confirm Email address"
                                ref={confirmEmailRef}
                            />
                            {confirmEmailError && (
                                <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                    Emails do not match. Please see the email again.
                                </div>
                            )}
                        </div>
                    <div className={`fw-bold font-20 cabin my-2`}>
                        Mobile Number
                    </div>
                    <div className={"d-flex flex-row w-100"}>
                        <InputGroup>
                            <FormSelect
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                                // style={{ width: "10px" }}
                            >
                                <option value={44}>+44</option>
                            </FormSelect>
                            {/*<Divider orientation="vertical" />*/}
                            <FormControl
                                ref={mobileNumberRef}
                                value={mobile}
                                onChange={(e) => handleMobileInput(e.target.value)}
                                maxLength={12}
                                placeholder="Mobile Number"
                                style={{width: '70%'}}
                            />
                        </InputGroup>
                        {mobileError && (
                            <div className={'fw-bold font-10 text-losing cabin my-2'}>
                                Please enter a valid mobile phone number.
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
    const renderDesktop = () => {
        return (
            <>
                <div className={"d-flex gap-4"}>
                    <div className={"w-50"}>
                        <div className={'fw-bold font-20 cabin mb-1'}>
                            Contact Information
                        </div>
                        <div className={"d-flex align-items-center justify-content-start gap-4"}>
                            <div className={"d-flex flex-column w-100"}>
                                <FormControl
                                    value={email}
                                    type="email"
                                    onChange={(e) => handleEmailInput(e.target.value)}
                                    placeholder="Email address"
                                    ref={emailRef}
                                />
                                <div className="error-container">
                                    {emailError && (
                                        <div className={'fw-bold font-10 text-losing cabin mt-2'}>
                                            Please enter a valid email address.
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className={"d-flex flex-column w-100"}>
                                <FormControl
                                    value={confirmEmail}
                                    type="email"
                                    onChange={(e) => handleConfirmEmailInput(e.target.value)}
                                    placeholder="Confirm Email address"
                                    ref={confirmEmailRef}
                                />
                                <div className="error-container">

                                {confirmEmailError && (
                                    <div className={'fw-bold font-10 text-losing cabin mt-2'}>
                                        Emails do not match. Please see the email again.
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"f-flex flex-column w-50"}>
                        <div className={'fw-bold font-20 cabin mb-1'}>
                            Mobile Number
                        </div>
                        <div className={"d-flex flex-column"}>
                            <InputGroup>
                                <FormSelect
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    // style={{ width: "10px" }}
                                >
                                    <option value={44}>+44</option>
                                </FormSelect>
                                {/*<Divider orientation="vertical" />*/}
                                <FormControl
                                    ref={mobileNumberRef}
                                    value={mobile}
                                    onChange={(e) => handleMobileInput(e.target.value)}
                                    maxLength={12}
                                    placeholder="Mobile Number"
                                    style={{width: '70%'}}
                                />
                            </InputGroup>
                            <div className="error-container">
                            {mobileError && (
                                <div className={'fw-bold font-10 text-losing cabin'}>
                                    Please enter a valid mobile phone number.
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    const renderMain = () => {
        return (
            <>
                {isMobile ? renderMobile() : renderDesktop()}
            </>
        );
    };

    return renderMain();
};

export default ContactInformationSignUpComponent;