import {AppSettingsActionType} from "../Constants/AppSettingsActionType";
import {FromPaymentScreenActionType} from "../Constants/FromPaymentScreenActionType";

const initialState = {
	fromPaymentScreen: false,
};

export const FromPaymentScreenReducer = (state = initialState, action) => {
	switch (action.type) {
	case FromPaymentScreenActionType.FromPaymentScreen:
		return {
			...state,
			fromPaymentScreen: action.payload,
		};
	default:
		return state;
	}
};
