import {IsEditActionType} from "../Constants/IsEditActionType";

const initialState = {
	isEdit: {},
};

export const IsEditReducer = (state = initialState, action) => {
	switch (action.type) {
	case IsEditActionType.IsEdit:
		return {
			...state,
			isEdit: action.payload,
		};
	default:
		return state;
	}
};
