// CustomModal
import React from "react";
import {Modal} from "react-bootstrap";

import IconSuccess from "../../assets/images/eoro-2024/icon-success.svg";
import IconError from "../../assets/images/eoro-2024/icon-modal-cross.svg";


const CustomModal = (props) => {
    const {
        showModal,
        setShowModal,
        message,
        onClick,
        modalStatus,
    } = props;


    const render = () => {
        return (
            <>
                <Modal
                    show={showModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    // onHide={() => {
                    //     // setShowModal(false)
                    // }}
                    onHide={()=>{
                        onClick();
                    }}
                >
                    <Modal.Header closeButton className={"border-0"}>

                    </Modal.Header>
                    <Modal.Body>
                        <div className={"d-flex flex-column gap-3 align-items-center justify-content-center pb-5"}>
                            {
                                modalStatus === "error" ?
                                    <>
                                        <img
                                            src={IconError}
                                            alt={"icon"}
                                            className={"img-fluid"}
                                            height={100}
                                            width={100}
                                        />
                                    </>
                                    :
                                    <>
                                        <img
                                            src={IconSuccess}
                                            alt={"icon"}
                                            className={"img-fluid"}
                                        />
                                    </>
                            }

                            <div className={"cabin fw-700 font-20 text-skins-blue text-center"}>
                                {message}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

    return render();
}

export default CustomModal;
