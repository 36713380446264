import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import moment from "moment";

import PersonalDetailsSignUpComponent from "../Components/SignUpScreenComponents/PersonalDetailsSignUpComponent";
import ContactInformationSignUpComponent from "../Components/SignUpScreenComponents/ContactInformationSignUpComponent";
import AddressInformationSignUpComponent from "../Components/SignUpScreenComponents/AddressInformationSignUpComponent";
import LoginScreenComponent from "../Components/SignUpScreenComponents/LoginScreenComponent";
import {useDispatch, useSelector} from "react-redux";
import {get} from "../Axios/get";
import {
    APP_LOGS,
    GAMSTOP_AND_USER_CHECK,
    GET_ALL_TEAMS,
    KYC,
    KYC_LOG, REGISTER_CHECK,
    REGISTER_USER,
    STAGING_BASE_URL
} from "../Axios/EndPoints";
import {post} from "../Axios/post";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setIsLogin, setToken, setUser} from "../redux/Actions/LoginAction";
import {
    setDisplayName,
    setEmailRedux,
    setKyc,
    setKycResponse,
    setUserNameRedux
} from "../redux/Actions/AuthenticationAction";
import {setFromPaymentScreen} from "../redux/Actions/FromPaymentScreenAction";
import axios from "axios";
import * as ENDPOINTS from "../Axios/EndPoints";
import CustomModal from "../Components/ModalComponent/CustomModal";

const Register = (props) => {
    const {isMobile} = props;
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const [isGamStopModal, setIsGamStopModal] = useState(false);
    const [title, setTitle] = useState("Mr");
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [userName, setUserName] = useState("");
    const [countryCode, setCountryCode] = useState();
    const [mobile, setMobile] = useState("");
    // console.log("mobile >>>", mobile)
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [postcode, setPostcode] = useState("");
    const [firstLineAddress, setFirstLineAddress] = useState("");
    const [secondLineAddress, setSecondLineAddress] = useState("");
    const [thirdLineAddress, setThirdLineAddress] = useState("");
    const [fourthLineAddress, setFourthLineAddress] = useState("");

    const [houseNumber, setHouseNumber] = useState("");
    const [townCity, setTownCity] = useState("");
    const [county, setCounty] = useState("");
    const [country, setCountry] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(moment().format("YYYY-MM-DD"));
    const [gamStopCheck, setGamStopCheck] = useState("");
    const [kycCheck, setKycCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [signupLoading, setSignupLoading] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState("");
    const [allTeams, setAllTeams] = useState(null);
    const [passwordIsNotStrongPopover, setPasswordIsNotStrongPopover] = useState(false);
    const [kycData, setKycData] = useState("");
    const [kycRequestSent, setKycRequestSent] = useState(0);
    const [restrictKycRequest, setRestrictKycRequest] = useState(false);

    // console.log("restrictKycRequest >>>>",restrictKycRequest)
    const [formattedAddress, setFormattedAddress] = useState("");
    const [addressLineOne, setAddressLineOne] = useState("");
    const [addressLineTwo, setAddressLineTwo] = useState("");
    const [addressLineThree, setAddressLineThree] = useState("");
    const [addressLineFour, setAddressLineFour] = useState("");

    const [flatNumber, setFlatNumber] = useState("");
    const [userUnderEighteen, setUserUnderEighteen] = useState(false);
    const [termsAndConditionsButtonDisabled, setTermsAndConditionsButtonDisabled] = useState(false);

    const [kycResponseForLogging, setKycResponseForLogging] = useState(null);
    const store = useSelector(state => state.TeamSelection);
    const SkinStore = useSelector(
        state => state.SelectedSkinData.selectedSkinData);
    const settingStore = useSelector(state => state?.AppSettings?.appSettings);

    // console.log("settingStore?.KYC_API_KEY >>>", settingStore?.KYC_API_KEY)

    const [showCustomModal, setShowCustomModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalStatus, setModalStatus] = useState("");

    const [documentVerification, setDocumentVerification] = useState(false);


    useEffect(() => {
        fetchTeams();
    }, []);

    useEffect(() => {
        if (kycRequestSent >= 2) {
            setRestrictKycRequest(true);
        }
    }, [kycRequestSent]);

    useEffect(() => {
    }, [isGamStopModal, restrictKycRequest]);

    useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    useEffect(() => {

    }, [userName, email, mobile]);

    useEffect(() => {
        if (password !== confirmPassword) {
            setPasswordError(true);
            setConfirmPasswordError(true);
        } else {
            setPasswordError(false);
            setConfirmPasswordError(false);
        }
    }, [password, confirmPassword]);


    const closeModalAndNavigate = () => {
        setIsGamStopModal(false);
        setRestrictKycRequest(false);
        setKycRequestSent(0);
        setRestrictKycRequest(false);
        navigate("/");
    };

    const gamStopCheckModal = () => {
        return (
            <Modal
                centered={true}
                size="md"
                show={isGamStopModal}
                onHide={closeModalAndNavigate}
            >
                <Modal.Header
                    className={"border-0"}
                    closeButton
                >
                </Modal.Header>
                <Modal.Body>
                    <div className={"d-flex align-items-center justify-content-center flex-column gap-2"}>
                        <img
                            alt={"info pic"}
                            src={require("../assets/images/gamStopCheck.png")}
                            // resizeMode={"cover"}
                            // width={75}
                            // height={75}
                            // marginTop={25}
                        />
                        <div className={'fw-bold font-20 cabin'}>YOU ARE SIGNED UP TO GAMSTOP</div>
                        <div className={'font-16 cabin text-center'}>
                            You are unable to play
                            SportSkins as you
                            are registered to the self-exclusion gambling
                            scheme via GamStop.
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const restrictKycRequestModal = () => {
        return (
            <Modal
                centered={true}
                size="md"
                show={restrictKycRequest}
                onHide={closeModalAndNavigate}
            >
                <Modal.Header
                    className={"border-0"}
                    closeButton
                >
                </Modal.Header>

                <Modal.Body>
                    <div className={"d-flex align-items-center justify-content-center flex-column gap-2"}>
                        <img
                            alt={"info pic"}
                            src={require("../assets/images/gamStopCheck.png")}
                            // resizeMode={"cover"}
                            // width={75}
                            // height={75}
                            // marginTop={25}
                        />
                        <div className={'fw-bold font-20 cabin'}>Sorry!</div>
                        <div className={'font-16 cabin text-center'}>
                            Some error occurred. Please contact us at
                            <a className={"text-decoration-none skins-link-red ms-2"}
                               href={"mailto:support@sportskins.uk"}>
                                support@sportskins.uk
                            </a>
                        </div>
                        <div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const fetchTeams = () => {
        get(GET_ALL_TEAMS).then(res => {
            if (res.data.success) {
                setAllTeams(res?.data?.data);
                // console.log("all tems >>>>", res?.data?.data)
            }
        }).catch(error => console.error("Error while getting settings",
            error.response));
    };

    const appLogData = (identifier, screen, line_number, log) => {
        // if(settingStore?.ENABLE_APP_LOGS === "YES")
        let isStaging = parseInt(params.get('staging'));
        let formData = new FormData();

        formData.append("identifier", identifier);
        formData.append("screen", screen);
        formData.append("line_number", line_number);
        formData.append("log", JSON.stringify(log));

        post(APP_LOGS, formData, false, "", null, isStaging ? STAGING_BASE_URL : '').then(res => {
            if (res.data.success === true) {
                console.log("log successfull")
            }
        }).catch(error => {
            console.error("error in app logs =", error);
        });
    }

    const registerUser = async (tmpKycData) => {
        let isStaging = parseInt(params.get('staging'));
        const tmpKycCheck = (tmpKycData?.kyc?.results?.w2DataEkycUk007BResult?.interpretResult?.toLowerCase() === "pass");
        const gameStopData = (tmpKycData?.kyc?.gamStop);

        /* const years = moment().diff(dateOfBirth, "years", false);
         if (years < 18) {
         setUserUnderEighteen(true);
         } else if (years > 18) {
         setUserUnderEighteen(false);
         } */

        let isSuccess = false;
        setLoading(true);

        let formData = new FormData();

        // const tempFullAddress = (addressLineOne + "," + addressLineTwo + "," + addressLineThree + "," + townCity + "," + county + "," + country + "," + postcode);
        // console.log("filetered formatted =", formattedAddress?.formatted_address);

        let dataForForm = {
            title: title,
            first_name: firstName,
            last_name: surname,
            username: userName,
            date_of_birth: dateOfBirth === "" ? newDate : dateOfBirth,
            phone_code: countryCode || "+44",
            mobile: mobile?.length > 11 ? mobile?.slice(1, mobile?.length) : mobile,
            email: email,
            password: password,
            password_confirmation: confirmPassword,
            gamstop_x_exclusion: gameStopData?.xExclusion || '',
            gamstop_x_unique_id: gameStopData?.xUniqueID || '',
            address: formattedAddress,
            house_number: houseNumber,
            street: formattedAddress?.thoroughfare || '',
            team_id: selectedTeam === "" ? 9053 : selectedTeam,
            postcode: postcode,
            city: townCity,
            county: county,
            country: country,
            kyc: tmpKycCheck === true ? 1 : 0,
            w2_kyc_check_request: JSON.stringify(tmpKycData?.kyc_log),
        }
        settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("dataForForm", "Register web Screen", 230, dataForForm)

        let filteredFormattedAddress = "";
        let UpdatedFormattedAddress = "";
        let thoroughfare = "";
        if (formattedAddress === "undefined" || formattedAddress === null || formattedAddress === '') {
            thoroughfare = addressLineOne;
            UpdatedFormattedAddress = `${addressLineOne || ''}, ${addressLineTwo || ''}, ${addressLineThree || ''}, ${addressLineFour || ''}`;
        }
        if (typeof formattedAddress === 'object') {
            try {
                filteredFormattedAddress = (formattedAddress?.formatted_address)?.filter(item => item.trim() !== "");
                UpdatedFormattedAddress = filteredFormattedAddress.join(", ");
                thoroughfare = formattedAddress?.thoroughfare;
            } catch (err) {
                console.log('formattedAddressError', err);
            }
        }
        // console.log("UpdatedFormattedAddress formatted =", UpdatedFormattedAddress);

        let newDate = moment(new Date()).format("YYYY-MM-DD");

        formData.append("title", title);
        formData.append("first_name", firstName);
        formData.append("last_name", surname);
        formData.append("username", userName);
        formData.append("date_of_birth", dateOfBirth === "" ? newDate : dateOfBirth);
        formData.append("phone_code", countryCode || "+44");
        formData.append("mobile", mobile?.length > 11 ? mobile.slice(1, mobile?.length) : mobile);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("password_confirmation", confirmPassword);
        formData.append("gamstop_x_exclusion", gameStopData?.xExclusion);
        formData.append("gamstop_x_unique_id", gameStopData?.xUniqueID);
        formData.append("address", UpdatedFormattedAddress);
        formData.append("house_number", houseNumber);
        formData.append("street", thoroughfare);
        formData.append("team_id", selectedTeam === "" ? 9053 : selectedTeam);
        formData.append("postcode", postcode);
        formData.append("city", townCity);
        formData.append("county", county);
        formData.append("country", country);
        formData.append("kyc", tmpKycCheck === true ? 1 : 0);
        formData.append("w2_kyc_check_request", JSON.stringify(tmpKycData?.kyc_log));

        await post(REGISTER_USER, formData, false, "", dispatch, isStaging ? STAGING_BASE_URL : '').then(res => {
            isSuccess = true;
            if (res?.data?.success === true) {
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("res?.data?.success", "Register web screen", 293, res?.data?.success)
                // dispatch(setToken(res?.data?.data?.token));
                // dispatch(setIsLogin(true));
                // dispatch(setDisplayName(res?.data?.data?.user?.display_name));
                // dispatch(setUserNameRedux(res?.data?.data?.user?.username));
                // dispatch(setEmailRedux(res?.data?.data?.user?.email));
                // dispatch(setKyc(res?.data?.data?.user?.kyc));
                navigate("/");
                setLoading(false);
                // dispatch(setUser(res?.data?.data));
            }
        }).catch(error => {
            setLoading(false);
            // alert(error?.response?.data.message ? error?.response?.data?.message : error);
            setShowCustomModal(true);
            setModalMessage(error?.response?.data.message ? error?.response?.data?.message : error);
            setModalStatus("error");

            console.error("error in submiting form =", error?.response?.data);
            settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("error in submiting form =", "Register web screen", 316, error?.response?.data)
        });

        // setLoading(false);

        if (isSuccess) {
            if (store?.teams[0] !== undefined) {
                dispatch(setFromPaymentScreen(true));
                Object.assign(SkinStore, {isPayment: true});
                if (tmpKycCheck === false) {
                    // navigation.navigate("DocumentVerification", {showModal: true, kycData: tmpKycData});
                    // UPDATE THE DocumentVerification ROUTE
                    // console.log("navigate to DocumentVerification")
                    setDocumentVerification(true);
                } else {
                    // navigation.navigate("GameScreen", {gameData: SkinStore});
                    // update the game screen route
                    // console.log("navigate to GameScreen");
                    navigate("/");
                }
            } else {
                if (tmpKycCheck === false) {
                    // navigation.navigate("DocumentVerification", {showModal: true, kycData: tmpKycData});
                    // console.log("navigate to DocumentVerification");
                    setDocumentVerification(true);
                } else {
                    // navigation.navigate("Home");
                    console.log("navigate to Home")
                    navigate("/");
                }
            }
        }

        console.log("isSuccess >>>", isSuccess)
        settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("isSuccess", "Register web screen", 346, isSuccess)
        return isSuccess;
    };

    const kycLogsStore = async (body, headers, response, url) => {
        let isStaging = parseInt(params.get('staging'));
        let data = {
            body: body,
            headers: headers,
            response: response,
            url: url,
        };
        post(KYC_LOG, data, false, "", dispatch, isStaging ? STAGING_BASE_URL : '')
            .then(res => {
                console.log("resKycLogs =", res.data);
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("resKycLogs =", "Register web screen", 361, res?.data)
            })
            .catch(error => {
                console.error("Error while submitting kycLogs", error);
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("Error while submitting kycLogs", "Register web screen", 365, error)
            });
    };

    const checkGamStopAndKYC = async () => {
        let isStaging = parseInt(params.get('staging'));
        let tmpKycData = "";
        let gamStopData = "";

        const years = moment().diff(dateOfBirth, "years", false);
        if (years < 18) {
            setUserUnderEighteen(true);
            // alert("You Are Not Old Enough To Gamble.");
            // navigation.navigate("Home");
            return;
        } else if (years >= 18) {
            setUserUnderEighteen(false);
        }

        let isSuccess = false;
        // setLoading(true);
        // setSignupLoading(true);

        let formData = new FormData();

        let data = {
            mobile: mobile,
            username: userName,
            email: email,
            firstname: firstName,
            lastName: surname,
            dateOfBirth: dateOfBirth,
            postcode: postcode,
        };

        // if(addressLineOne === houseNumber)
        // {
        // 	alert("Your House/Flat Number is same as the Address Line One");
        // 	return;
        // }

        if (
            (mobile === "" && typeof mobile === "undefined") || (firstName === "" && typeof firstName === "undefined") ||
            (surname === "" && typeof surname === "undefined") ||
            (postcode === "" && typeof postcode === "undefined") || (houseNumber === "" && typeof houseNumber === "undefined") ||
            (addressLineOne === "" && typeof addressLineOne === "undefined")) {
            // alert("Please Enter Complete Details On The Fields Above");
            setShowCustomModal(true);
            setModalMessage("Please Enter Complete Details On The Fields Above");
            setModalStatus("error");
            setLoading(false);
            setSignupLoading(false);
            return;
        }


        formData.append("mobile", mobile);
        formData.append("username", userName);
        formData.append("email", email);
        formData.append("firstname", firstName);
        formData.append("lastName", surname);
        formData.append("dateOfBirth", dateOfBirth);
        formData.append("postcode", postcode?.replace(/\s/g, ""));

        let gamStopHeaders = {
            // Authorization: "Basic " + "1cf2fe3c-6d9e-4791-9ede-9c5f32bd60f1",
            // Accept: "application/json",
            "Content-Type": "multipart/form-data",
        };
        console.log("ENDPOINTS.GAMSTOP_AND_USER_CHECK", GAMSTOP_AND_USER_CHECK);
        settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("ENDPOINTS.GAMSTOP_AND_USER_CHECK", "Register web screen", 433, ENDPOINTS.GAMSTOP_AND_USER_CHECK)
        // axios.post('https://api.sportskins.uk/api/v1'+ENDPOINTS.GAMSTOP_AND_U§SER_CHECK, formData, { headers: gamStopHeaders })

        await post(GAMSTOP_AND_USER_CHECK, data, false, "", dispatch, isStaging ? STAGING_BASE_URL : '')
            .then(res => {
                console.log("resGamStop =", res.data, res?.data?.data?.gamstop?.headers);
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("resGamStop1 =", "Register web screen", 439, res.data)
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("resGamStop2 =", "Register web screen", 439, res?.data?.data?.gamstop?.headers)

                if (res?.data?.data?.is_restricted) {
                    setRestrictKycRequest(true);
                    return false;
                }
                if (res?.data?.data?.unique) {
                    if (res?.data?.data?.gamstop?.headers["x-exclusion"][0] !== "Y") {
                        setEmailError(false);
                        setUsernameError(false);
                        setMobileError(false);

                        let tmp = {
                            xExclusion: res?.data?.data?.gamstop?.headers["x-exclusion"][0],
                            xUniqueID: res?.data?.data?.gamstop?.headers["x-unique-id"][0],
                        };
                        isSuccess = true;
                        gamStopData = tmp;
                        setGamStopCheck(tmp);
                        // setLoading(false);
                        setSignupLoading(false);
                    } else {
                        setIsGamStopModal(true);
                        setSignupLoading(false);
                        setLoading(false);
                        return false;
                    }
                    // return true;
                } else if (!res?.data?.data?.unique) {
                    setIsGamStopModal(true);
                    setSignupLoading(false);
                    setLoading(false);
                    return false;
                }
            })
            .catch(error => {
                console.error("Error while submitting gamestop", error);
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("Error while submitting gamestop", "Register web screen", 477, error)
                if (error?.response?.data?.message?.key === "EMAIL") {
                    // alert(error?.response?.data?.message?.text);
                    setShowCustomModal(true);
                    setModalMessage(error?.response?.data?.message?.text);
                    setModalStatus("error");
                    setEmailError(true);
                } else if (error?.response?.data?.message?.key === "MOBILE") {
                    // alert(error?.response?.data?.message?.text);
                    setShowCustomModal(true);
                    setModalMessage(error?.response?.data?.message?.text);
                    setModalStatus("error");
                    setMobileError(true);
                } else if (error?.response?.data?.message?.key === "USERNAME") {
                    // alert(error?.response?.data?.message?.text);
                    setShowCustomModal(true);
                    setModalMessage(error?.response?.data?.message?.text);
                    setModalStatus("error");
                    setUsernameError(true);
                }
                setLoading(false);
                return false;
            });

        if (isSuccess) {
            // const filteredFormattedAddress = (formattedAddress)?.filter(item => item.trim() !== "");
            // const UpdatedFormattedAddress = filteredFormattedAddress.join(", ");
            let LogData = {
                data: null,
                headers: null,
                url: null,
                response: null,
            };

            let KYCDATA = {
                "Bundle": settingStore?.KYC_BUNDLE_ID || "SIS_PEP_KYC_UK_007B_015" || "KYC_UK_007B",
                "Data": {
                    "DayOfBirth": parseInt(dateOfBirth?.slice(-2)),
                    "DateOfBirthMatchThreshold": 60,
                    "Forename": firstName,
                    "Surname": surname,
                    "MonthOfBirth": parseInt(dateOfBirth.slice(5, 7)),
                    "NameQuery": firstName + " " + surname,
                    "NameQueryMatchThreshold": 100,
                    "Postcode": postcode?.replace(/\s/g, ""),
                    "YearOfBirth": parseInt(dateOfBirth?.slice(0, 4)),
                    "HouseNumber": houseNumber,
                    "Street": formattedAddress?.thoroughfare,
                },
                "Options": {
                    // "Sandbox": "true", // use sandbox for staging testing only
                    "IncludeCountries": "GBR",
                    "ExcludeExPeps": "true",
                },
                // "ClientReference": "Production",
            };

            console.log("kyc =", KYCDATA);
            settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("kyc =", "Register web screen", 527, KYCDATA)

            let kycHeaders = {
                // Authorization: "Basic " + "828aaaf3-5a3d-4593-a364-73b06fcc10c7",
                Authorization: "Basic " + settingStore?.KYC_API_KEY || "828aaaf3-5a3d-4593-a364-73b06fcc10c7",
                Accept: "application/json",
                "Content-Type": "application/json",
            };

            setKycRequestSent(prevState => ++prevState);

            await axios.post(KYC, KYCDATA, {headers: kycHeaders})
                .then(res => {
                    console.log("kycRequestSentLogCheck");
                    settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("kycRequestSentLogCheck", "Register web screen", 540, res)

                    isSuccess = true;
                    LogData.response = res.data;
                    const tmp = (res?.data?.results?.w2DataEkycUk007BResult?.interpretResult?.toLowerCase() === "pass");
                    setKycCheck(tmp);
                    tmpKycData = res?.data;
                    dispatch(setKycResponse(tmpKycData));
                    setKycData(tmpKycData);
                    // setLoading(false);
                    kycLogsStore(KYCDATA, kycHeaders, res?.data, KYC);
                })
                .catch(error => {
                    kycLogsStore(KYCDATA, kycHeaders, error.data || error.response.data || error, KYC);
                    setLoading(false);
                    console.error("Error while submitting kyc", error.data || error.response.data || error);
                    settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("Error while submitting kyc", "Register web screen", 563, error.data || error.response.data || error)
                });

            LogData.data = KYCDATA;
            LogData.headers = kycHeaders;
            LogData.url = KYC;
            setKycResponseForLogging(LogData);
            setLoading(false);

            Object.assign(tmpKycData, {gamStop: gamStopData});

            return {
                "kyc": tmpKycData,
                "kyc_log": LogData,
            };
            // return tmpKycData === null ? false : tmpKycData;
        }
        return false;
    };

    const checkEmail = async () => {
        let isStaging = parseInt(params.get('staging'));
        let isSuccess = false;
        setLoading(true);

        let formData = new FormData();

        formData.append("type", "email");
        formData.append("value", email);
        await post(REGISTER_CHECK, formData, false, "", dispatch, isStaging ? STAGING_BASE_URL : '').then(res => {
            isSuccess = true;
            console.log("res.data =", res.data);
            if (res.data.success === true) {
                // setLoading(false);
            }
        }).catch(error => {
            console.error("Error while checking email", error);
            setLoading(false);
            return false;
        });
        return isSuccess;
    };

    const handleUniqueCheck = async () => {
        setLoading(true);
        setSignupLoading(true);

        const uniqueEmail = await checkEmail();
        console.log("unique Email =", uniqueEmail);
        if (uniqueEmail) {
            console.log("unique email");
            handleState();
        } else {
            // setEmailError(true);
            // setConfirmEmailError(false);
            setLoading(false);
            // setSignupLoading(false);
            // alert("Email Already registered!");
            setShowCustomModal(true);
            setModalMessage("Email Already registered!");
            setModalStatus("error");

        }
    };

    const checkStrongPassword = (password) => {
        // Regular expression to check if password is strong
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
    };
    const handleState = async () => {
        if (
            checkStrongPassword(password) &&
            checkStrongPassword(confirmPassword) &&
            password === confirmPassword
        ) {
            setLoading(true);
            try {
                const res = await checkGamStopAndKYC();
                console.log("response for checkGamStopAndKYC = ", res)

                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("response for checkGamStopAndKYC = ", "Register web screen", 647, res)

                if (res) {
                    console.log("kycGamStopChecked", res);
                    settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("kycGamStopChecked", "Register web screen", 652, res)
                    await registerUser(res);
                    setPasswordIsNotStrongPopover(false);
                    setLoading(false);
                } else {
                    setLoading(false);
                    // alert("Please try again!");
                    setShowCustomModal(true);
                    setModalMessage("Please try again!");
                    setModalStatus("error");
                }
            } catch (error) {
                console.error("Error occurred while checking GAM stop and KYC:", error);
                settingStore?.ENABLE_APP_LOGS === "YES" && appLogData("Error occurred while checking GAM stop and KYC:", "NewSignUpScreen", 663, error)
                // alert("An error occurred. Please try again!");
                setShowCustomModal(true);
                setModalMessage("An error occurred. Please try again!");
                setModalStatus("error");
            }

        } else {
            setLoading(false);
            setPasswordIsNotStrongPopover(true);
        }
        setLoading(false);
    };

    const onClickModal = () => {
        setShowCustomModal(false);
        setModalMessage("");
        setModalStatus("");

    }

    const documentVerificationModal = () => {
        return (
            <Modal
                centered={true}
                size="md"
                show={documentVerification}
                onHide={() => {
                    // e.preventDefault();
                    setDocumentVerification(false);
                    navigate("/");
                }}
            >
                <Modal.Header
                    className={"border-0"}
                    closeButton
                >
                </Modal.Header>
                <Modal.Body>
                    <div className={"d-flex align-items-center justify-content-center flex-column gap-2 mb-4"}>
                        <img
                            alt={"info pic"}
                            src={require("../assets/images/gamStopCheck.png")}
                            height={150}
                        />
                        <div className={'fw-bold font-20 cabin text-center'}>Alas!</div>
                        <div className={'font-16 cabin text-center'}>
                            Your KYC has failed. Please login to our mobile application to verify your documents and
                            complete KYC.
                        </div>
                        <div className={'font-16 cabin text-center'}>
                            Download our mobile application if you have not done so yet.
                        </div>
                        <div className={"d-flex flex-row gap-3"}>
                            <div className={"border"}><a href={'https://play.google.com/store/apps/details?id=uk.sportskins'}
                                    target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/home/google_play.png')}
                                    alt={'Home Google Play'}
                                    height={40}
                                />
                            </a></div>
                            <div className={"border"}><a href={'https://apps.apple.com/gb/app/sportskins-football-betting/id1668307911'}
                                    target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/home/app_store.png')}
                                    alt={'Home App Store'}
                                    height={40}
                                />
                            </a></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const renderMobile = () => {
        return (
            <>
                <div className={'text-center fw-bold font-60 h1 text-white gibson text-uppercase'}>
                    <span className={''}>Sign</span> {' '}
                    <span className={'text-skins-teal'}>Up</span>
                </div>
                <Container className={"pt-4 pb-5 bg-team-card-grey rounded-my-skins-top"}>
                    <Form className={"d-flex flex-column gap-4"} autoComplete={"off"}>
                        <PersonalDetailsSignUpComponent
                            isMobile={isMobile}
                            title={title}
                            setTitle={setTitle}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            surname={surname}
                            setSurname={setSurname}
                            dateOfBirth={dateOfBirth}
                            setDateOfBirth={setDateOfBirth}
                            userUnderEighteen={userUnderEighteen}
                        />
                        <hr className={'skins-hr w-100'}/>
                        <ContactInformationSignUpComponent
                            isMobile={isMobile}
                            email={email}
                            setMobile={setMobile}
                            confirmEmail={confirmEmail}
                            setConfirmEmail={setConfirmEmail}
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                            mobile={mobile}
                            setEmail={setEmail}
                            mobileError={mobileError}
                            setMobileError={setMobileError}
                            emailError={emailError}
                            setEmailError={setEmailError}
                        />
                        <hr className={'skins-hr w-100'}/>
                        <AddressInformationSignUpComponent
                            isMobile={isMobile}
                            postcode={postcode}
                            setLoading={setLoading}
                            setPostcode={setPostcode}
                            loading={loading}
                            houseNumber={houseNumber}
                            setHouseNumber={setHouseNumber}
                            townCity={townCity}
                            setTownCity={setTownCity}
                            county={county}
                            setCounty={setCounty}
                            country={country}
                            setCountry={setCountry}
                            setFirstLineAddress={setFirstLineAddress}
                            firstLineAddress={firstLineAddress}

                            setFormattedAddress={setFormattedAddress}
                            addressLineOne={addressLineOne}
                            setAddressLineOne={setAddressLineOne}
                            addressLineTwo={addressLineTwo}
                            setAddressLineTwo={setAddressLineTwo}
                            addressLineThree={addressLineThree}
                            setAddressLineThree={setAddressLineThree}
                            addressLineFour={addressLineFour}
                            setAddressLineFour={setAddressLineFour}
                            flatNumber={flatNumber}
                            setFlatNumber={setFlatNumber}
                        />
                        <hr className={'skins-hr w-100'}/>
                        <LoginScreenComponent
                            isMobile={isMobile}
                            usernameError={usernameError}
                            userName={userName}
                            setUserName={setUserName}
                            password={password}
                            setPassword={setPassword}
                            confirmPasswordError={confirmPasswordError}
                            setConfirmPassword={setConfirmPassword}
                            confirmPassword={confirmPassword}
                            passwordError={passwordError}
                            setUsernameError={setUsernameError}
                            selectedTeam={selectedTeam}
                            allTeams={allTeams}
                            setAllTeams={setAllTeams}
                            setSelectedTeam={setSelectedTeam}
                            passwordIsNotStrongPopover={passwordIsNotStrongPopover}
                            setPasswordIsNotStrongPopover={setPasswordIsNotStrongPopover}
                            setTermsAndConditionsButtonDisabled={setTermsAndConditionsButtonDisabled}
                            termsAndConditionsButtonDisabled={termsAndConditionsButtonDisabled}

                            setModalMessage={setModalMessage}
                            setShowCustomModal={setShowCustomModal}
                            setModalStatus={setModalStatus}
                        />
                        <Button
                            role={"submit"}
                            onClick={loading ? null : handleUniqueCheck}
                            disabled={!termsAndConditionsButtonDisabled || loading}
                            className={'btn-skins-blue'}
                        >
                            Sign Up
                        </Button>
                    </Form>
                </Container>

            </>
        );
    }

    const renderDesktop = () => {
        return (
            <>
                {/*<Row>*/}
                {/*    <div className={'text-center fw-bold font-60 h1 text-white gibson text-uppercase'}>*/}
                {/*        <span className={''}>Sign</span> {' '}*/}
                {/*        <span className={'text-skins-teal'}>Up</span>*/}
                {/*    </div>*/}
                {/*</Row>*/}
                <Container className={"p-4 register-desktop"}>
                    <Form className={"d-flex flex-column gap-3"}>
                        <PersonalDetailsSignUpComponent
                            isMobile={isMobile}
                            title={title}
                            setTitle={setTitle}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            surname={surname}
                            setSurname={setSurname}
                            dateOfBirth={dateOfBirth}
                            setDateOfBirth={setDateOfBirth}
                            userUnderEighteen={userUnderEighteen}
                        />
                        {/*<hr className={'skins-hr w-100'}/>*/}
                        <ContactInformationSignUpComponent
                            isMobile={isMobile}
                            email={email}
                            setMobile={setMobile}
                            confirmEmail={confirmEmail}
                            setConfirmEmail={setConfirmEmail}
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                            mobile={mobile}
                            setEmail={setEmail}
                            mobileError={mobileError}
                            setMobileError={setMobileError}
                            emailError={emailError}
                            setEmailError={setEmailError}
                        />
                        {/*<hr className={'skins-hr w-100'}/>*/}
                        <AddressInformationSignUpComponent
                            isMobile={isMobile}
                            postcode={postcode}
                            setLoading={setLoading}
                            setPostcode={setPostcode}
                            loading={loading}
                            houseNumber={houseNumber}
                            setHouseNumber={setHouseNumber}
                            townCity={townCity}
                            setTownCity={setTownCity}
                            county={county}
                            setCounty={setCounty}
                            country={country}
                            setCountry={setCountry}
                            setFirstLineAddress={setFirstLineAddress}
                            firstLineAddress={firstLineAddress}

                            setFormattedAddress={setFormattedAddress}
                            addressLineOne={addressLineOne}
                            setAddressLineOne={setAddressLineOne}
                            addressLineTwo={addressLineTwo}
                            setAddressLineTwo={setAddressLineTwo}
                            addressLineThree={addressLineThree}
                            setAddressLineThree={setAddressLineThree}
                            addressLineFour={addressLineFour}
                            setAddressLineFour={setAddressLineFour}
                            flatNumber={flatNumber}
                            setFlatNumber={setFlatNumber}
                        />
                        {/*<hr className={'skins-hr w-100'}/>*/}
                        <LoginScreenComponent
                            isMobile={isMobile}
                            usernameError={usernameError}
                            userName={userName}
                            setUserName={setUserName}
                            password={password}
                            setPassword={setPassword}
                            confirmPasswordError={confirmPasswordError}
                            setConfirmPassword={setConfirmPassword}
                            confirmPassword={confirmPassword}
                            passwordError={passwordError}
                            setUsernameError={setUsernameError}
                            selectedTeam={selectedTeam}
                            allTeams={allTeams}
                            setAllTeams={setAllTeams}
                            setSelectedTeam={setSelectedTeam}
                            passwordIsNotStrongPopover={passwordIsNotStrongPopover}
                            setPasswordIsNotStrongPopover={setPasswordIsNotStrongPopover}
                            setTermsAndConditionsButtonDisabled={setTermsAndConditionsButtonDisabled}
                            termsAndConditionsButtonDisabled={termsAndConditionsButtonDisabled}

                            setModalMessage={setModalMessage}
                            setShowCustomModal={setShowCustomModal}
                            setModalStatus={setModalStatus}
                        />
                        <div className={"d-flex align-items-center w-100 justify-content-center"}>

                            <Button
                                role={"submit"}
                                onClick={loading ? null : handleUniqueCheck}
                                disabled={(!termsAndConditionsButtonDisabled) || loading}
                                className={'btn-skins-blue w-50'}
                            >
                                Sign Up
                            </Button>
                        </div>
                    </Form>
                </Container>
            </>
        );
    }
    const render = () => {
        return (
            <section
            >
                <Helmet>
                    <title>Sportskins - Sign Up</title>
                    <meta name="description" content="Sportskins- Sign Up"/>
                </Helmet>
                {isGamStopModal && gamStopCheckModal()}
                {restrictKycRequest && restrictKycRequestModal()}
                {isMobile ? renderMobile() : renderDesktop()}
                {
                    showCustomModal &&
                    <CustomModal
                        showModal={showCustomModal}
                        setShowModal={setShowCustomModal}
                        message={modalMessage}
                        modalStatus={modalStatus}
                        onClick={onClickModal}
                    />
                }
                {
                    documentVerification && documentVerificationModal()
                }
            </section>
        );
    }

    return render();
}

export default Register;
