import {AppSettingsActionType} from "../Constants/AppSettingsActionType";

const initialState = {
	appSettings: {},
};

export const AppSettingsReducers = (state = initialState, action) => {
	switch (action.type) {
	case AppSettingsActionType.SET_SETTINGS:
		return {
			...state,
			appSettings: action.payload,
		};
	default:
		return state;
	}
};
